import TruncatedText from '../../../components/TruncatedText'

function FeaturedCreative(props) {
  function EmptyFeatured() {
    return (
      <div className="flex-none card w-96 h-128 shadow-xl animate-pulse ">
        <figure className="bg-gray-300 h-96 w-full "> </figure>
        <div className="card-body">
          <div className="w-36 bg-gray-300 h-6 rounded-md "></div>
          <div className="w-24 bg-gray-300 h-6 rounded-md "></div>
        </div>
      </div>
    )
  }
  function LoadedFeatured() {
    return (
      <div onClick={props.onClick} className="relative border-slate-800 border-4 w-fit">
        <div className={"relative " + (!!props.onClick && "cursor-pointer")}>
          <img className="min-w-0" src={props.image} alt={props.title} />
          {
            !!props.onClick && 
            <div className="absolute inset-0 hover:transition-all duration-200 opacity-0 hover:opacity-100 hover:bg-gradient-to-t hover:from-slate-900  ">
              <div className="absolute bottom-4 px-4 text-white prose-h1:text-white prose prose-sm md:prose-base">
                <h1 className="mb-0 sm:mb-1">{props.title}</h1>
                <p className="hidden sm:block">
                  <TruncatedText
                    text={props.body}
                    textLength={350} />
                </p>
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
  return (
    !props.loading ?
      <LoadedFeatured />
    : <EmptyFeatured />
  )
}

export default FeaturedCreative;