import { useEffect, useRef, useState } from 'react';
import EQMDEditor from './md-editor/EQMDEditor';
import { commands } from '@uiw/react-md-editor';
import rehypeSanitize from "rehype-sanitize";
import { useCreateComment } from '../../../../../hooks/comments';
import remarkGemoji from 'remark-gemoji'
import Picker from 'emoji-picker-react';

// import { comment, commentLoading } from './md-editor/CommentCommand'

function WriteCommentSimple({rootComment, replyingToComment, onSuccess}) {
  const [value, setValue] = useState("");
  const writeCommentMutation = useCreateComment()
  let defaultClass = 'save-comment'
  let loadingClass = 'save-comment loading'
  const isDisabled = value === '';
  const buttonEl = useRef(null);
  useEffect(() => {
    if (buttonEl?.current) {
      if (isDisabled) {
        buttonEl.current.disabled = true
        buttonEl.current.onclick = void(0)
        buttonEl.current.className = defaultClass
      } else if (writeCommentMutation.isLoading){
        buttonEl.current.disabled = true
        buttonEl.current.onclick = void(0)
        buttonEl.current.className = loadingClass
      } else {
        buttonEl.current.disabled = false
        buttonEl.current.onclick = createComment
        buttonEl.current.className = defaultClass
      }
    }
  })
  
  

  async function createComment() {
    console.log(value)
    const payload = {
      text: value,
      forum: rootComment.forum,
      rootCommentUUID: rootComment.uuid,
      root_comment: rootComment.url,
      replying_to: replyingToComment.url,
    }

    try {
      await writeCommentMutation.mutateAsync(payload)
      setValue('');
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      console.error(error)
    }
  }


  const commentCommand = { 
    name: 'comment',
    keyCommand: 'comment',
    // buttonProps: {
    //   'aria-label': 'Save Comment',
    //   title: 'Save Comment',
    //   className: defaultClass,
    //   disabled: isDisabled,
    //   ref: buttonEl,
    // },
    icon: (
      <button
        ref={buttonEl}
        disabled={isDisabled}
        className={defaultClass}
        onClick={() => createComment()}>
          <span>Comment</span>
      </button>
    ),
    // execute: (state) => {
    //   createComment(state.text)
    // },
  };

  const emojiPicker = { 
    name: 'emoji',
    groupName: 'emoji',
    keyCommand: 'emoji',
    buttonProps: {
      'aria-label': 'Pick Emoji',
      title: 'Pick Emoji',
    },
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none" viewBox="2 2 20 20" stroke="currentColor" strokeWidth={2}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
    ),
    children: (handle) => {
      function handleEmojiClick(event, emojiObject) {
        const api = handle.textApi
        console.log(emojiObject)
        api.replaceSelection(emojiObject.emoji)
        handle.close()
      }
      return (
        <Picker
          onEmojiClick={handleEmojiClick} />
      )
    },
    execute: (state, api, emojiObject) => {
      console.log(state)
      console.log(api)
      console.log(emojiObject)
    },
  };

  return (
    <div className="container font-sans">
      {/* title & link */}

      <EQMDEditor
        className='eq'
        value={value}
        height={100}
        onChange={setValue}
        preview={'edit'}
        // hideToolbar={true}
        previewOptions={{
          skipHtml: true,
          rehypePlugins: [[rehypeSanitize]],
          remarkPlugins: [[remarkGemoji]]
        }}
        textareaProps={{
          placeholder: 'What do you think?',
        }}
        toolbarBottom={true}
        commands={
          [
            commands.bold,
            commands.italic,
            commands.link,
            commands.strikethrough,
            // commands.comment,
            commands.quote,
            commands.group([], emojiPicker),
          ]
        }
        extraCommands={[
          commentCommand,
        ]}
        toolbarHeight={34}
      />
      {/* <div className="mt-2 text-right">
        {
          writeCommentMutation.isLoading ?
          <button className="btn btn-primary loading"></button>

          : <button
              onClick={ () => createComment() }
              className="btn btn-primary"
              disabled={ value === '' }>
                Comment
            </button>
        }
      </div> */}
    </div>
  );
}
export default WriteCommentSimple;
  