
  import { Outlet } from "react-router-dom";

  function ForumPage() {
    return (
      <div className="">
        <Outlet />
      </div>
    )
  }
  
  export default ForumPage;
  