import GenericSearchableList from "../../../components/GenericSearchableList";
import CreativeList from "./CreativeList";

function AllCreatives() {
  return (
    <GenericSearchableList title="Creatives" className="mt-4">
      <CreativeList />
    </GenericSearchableList>
  )
}

export default AllCreatives;
  