import BounceClickIcon from "./BounceClickIcon";

export default function EditIcon(props) {
  return (
    <BounceClickIcon {...props}>
      {
        <path strokeLinecap="round" strokeLinejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
      }
    </BounceClickIcon>
  )
}