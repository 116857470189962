export default function CommentFilterTab({ order, action }) {
  
  function getTabActive(tabName) {
    if (order === tabName) {
      return 'tab-active'
    }
    return ''
  }

  return (
    <div className="tabs">
      <span
        className={ `tab ${getTabActive('-created')}` }
        onClick={ () => action('-created') }>
          Latest
      </span> 
      <span
        className={ `tab ${getTabActive('-score')}` }
        onClick={ () => action('-score') }>
          Best
      </span> 
    </div>
  )
}