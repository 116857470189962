import { useState } from "react";
import { useEditMe } from "../../../hooks/users";

export default function ProfilePicture({ handle, image, isSelf }) {
  const [imagePreview, setImagePreview] = useState()
  const editMeMutation = useEditMe();
  let editableImageClasses = '';
  if (isSelf) {
    editableImageClasses = 'cursor-pointer hover:opacity-50'
  }

  function selectImage() {
    document.getElementById("selectImage").click()
  }
  
  function handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setImagePreview(reader.result)
    }

    reader.readAsDataURL(file)

    let formData = new FormData();
    formData.append('image', file, file.name);
    editMeMutation.mutate(formData)
  }

  return (
    <div className={`avatar placeholder ${editableImageClasses}`} onClick={() => isSelf && selectImage()}>
      <div className="w-24 h-24 mask mask-hexagon-2 rounded-sm bg-neutral-focus text-neutral-content">
        {
          !imagePreview && (image ? 
            <img src={image} alt={handle} />
          : <span className="text-3xl">{ handle.substr(0,1) }</span>)
        }
        {
          imagePreview && <img src={imagePreview} alt={handle} />
        }
      </div>
      <input id="selectImage" className="hidden" accept="image/png, image/jpeg" type="file" onChange={handleImageChange} />
    </div>
  )
}