export default function CommentAvatar({ user }) {
  return (
    <div className={`avatar ${user.image ? '' : 'placeholder'}`}>
      <div className="bg-neutral-focus text-neutral-content rounded-full w-8">
        {
          user.image ? 
            <img src={user.image} alt={user.handle}/>
          : 
            <span>{user.handle[0]}</span>
        }
      </div>
    </div>
  )
}