import {
  useParams,
  Link,
} from "react-router-dom";


import useApi from '../../../hooks/useApi'
import { useCreativeURL } from '../../../hooks/creatives'
import GenericCard from "../../../components/GenericCard";
import BelongsToDropIndicator from "../../../components/BelongsToDropIndicator";
import MintDropButton from "../../../components/MintDropButton";
import AuctionEndCountdown from "./AuctionEndCountdown";
import SupplyProgress from "./SupplyProgress";

function DropDetail(props) {
  let params = useParams();
  const dropUUID = params.dropUUID
  const opts = {
    method: 'GET',
  };
  const dropAPI = useApi(
    `drops/${dropUUID}/`,
    opts
  );

  const creativeAPI = useCreativeURL(dropAPI.data && dropAPI.data.creative);

  const keyMap = {
    title: 'name',
    body: 'description',
    image: 'image'
  }
  return (
    <div className="flex h-full justify-center">
      <div className="flex-col w-full space-y-8 px-4 sm:px-16 pb-4">
        <div className="space-y-2 mb-16">
          <h1 className="text-center text-2xl sm:text-4xl font-bold flex-row">
            <Link className="hover:text-violet-500 flex-row" to={`/creatives/${creativeAPI.data?.uuid}`}>
              {creativeAPI.data && creativeAPI.data.name}
            </Link>
          </h1>
          <h1 className="text-center text-3xl sm:text-5xl font-light mt-2 italic">
            {dropAPI.data && dropAPI.data.name}
          </h1>
        </div>
        <div className="flex flex-col lg:flex-row space-y-12 lg:space-y-0 lg:space-x-12 md:mx-auto lg:w-full ">
          <div className="flex w-10/12 mx-auto md:w-6/12 justify-center">
            <GenericCard
              loading={dropAPI.loading}
              data={dropAPI.data}
              keyMap={keyMap}
              indicator={
                creativeAPI.data && 
                <BelongsToDropIndicator
                  dropID={dropAPI.data.uuid_to_hex}
                  contractAddress={creativeAPI.data.contract_address} />
              }
              // onClick={() => onClick(dropData[k].uuid)}
              />
          </div>
          <div className="flex flex-col prose w-full lg:w-6/12 justify-center md:justify-start text-center md:text-left space-y-4">
            
            <div className="w-full">
              <h2 className="my-0">Description:</h2>
              <p className="m-0">{dropAPI.data && dropAPI.data.description}</p>
            </div> 

            <div className="flex flex-col w-full md:flex-row flex-wraps space-y-4">
              
              <div className="w-full md:w-6/12">
                <h2 className="my-0">Auction Ends:</h2>
                {
                  (dropAPI.data && dropAPI.data.end_time) ?
                    <AuctionEndCountdown end={dropAPI.data.end_time} />
                  : <p>Until Supply Runs Out</p>
                }
              </div> 

              <div className="w-full sm:w-6/12 mx-auto">
                {
                  creativeAPI.data && dropAPI.data &&
                  <SupplyProgress 
                    contractAddress={creativeAPI.data.contract_address}
                    dropID={dropAPI.data.uuid_to_hex}
                    max={dropAPI.data.max_supply}
                    price={parseFloat(dropAPI.data.price).toString()}/>
                }
              </div>
            </div>
            <div className="w-full">
              {
                creativeAPI.data &&
                <MintDropButton
                  dropID={dropAPI.data.uuid_to_hex}
                  contractAddress={creativeAPI.data.contract_address}
                  price={dropAPI.data.price}/>
              }
            </div>
          </div>
        </div>
        {/* <div className="mt-48 mx-auto">
          <div className="flex flex-row mt-8">
            <h1 className="text-xl font-bold w-6/12">Upcoming Events</h1>
            <h1 className="text-right w-6/12">
              <Link className="hover:text-slate-900 hover:underline text-xl text-slate-700 font-medium" to='/events'>View All</Link>
            </h1>
          </div>
          <div className="divider mt-4"></div>
          <EventList />
        </div> */}
      </div>
    </div>
  )
}
  
  export default DropDetail;
  