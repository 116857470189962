import GenericSearchableList from "../../../components/GenericSearchableList";
import DropList from "./DropList";

function AllDrops() {
  return (
    <GenericSearchableList title="Drops">
      <DropList />
    </GenericSearchableList>
  )
}

export default AllDrops;
  