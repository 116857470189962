function TruncatedText(props) {
  let newText = props.text || ''
  const textLength = props.textLength || 350
  if (newText.length > textLength) {
    newText = newText.substring(0,textLength) + '...'
  }
  return (
    <span>{newText}</span>
  )
}

export default TruncatedText;