import {
  Link,
  useParams,
} from "react-router-dom";
import FeaturedCreative from './FeaturedCreative';
import { useCreative, useMemberOf } from "../../../hooks/creatives";
import DropList from "../../drops/components/DropList";
import ForumDetailHOC from "../../forums/components/ForumDetailHOC";
import MemberTable from "./MemberTable";
import MemberOfTable from "./MemberOfTable";

const POLYGONSCAN = process.env.REACT_APP_POLYGONSCAN

function CreativeDetail(props) {
  let params = useParams();
  const creativeUUID = params.creativeUUID
  const { isLoading, data } = useCreative(creativeUUID);
  const memberOfAPI = useMemberOf(creativeUUID)
  const polygonScanURL = data ? `${POLYGONSCAN}address/${data.contract_address}` : ''
  return (
    <div className="flex h-full justify-center">
      <div className="flex-col space-y-8 px-4 sm:px-16 pb-4">
        <h1 className="text-center text-3xl lg:text-5xl font-bold">
          <div className="tooltip tooltip-bottom" data-tip="view on polygonscan">
            <a
              className="hover:text-violet-500"
              target="_blank"
              rel="noreferrer"
              href={polygonScanURL}>
                {data && data.name}
            </a>
          </div>
        </h1>
        <div className="flex flex-col lg:flex-row space-y-12 lg:space-y-0 lg:space-x-12 lg:justify-center md:w-8/12 md:mx-auto lg:w-full ">
          <div className="w-full lg:w-5/12">
            <FeaturedCreative
              hideText={true}
              loading={isLoading}
              image={data ? data.image : undefined}
              title={data ? data.name : undefined}
              body={data ? data.bio : undefined}>
              <button className="btn btn-primary">View</button>
            </FeaturedCreative>
          </div>
          <div className="prose">
            <p>{data && data.bio}</p>
          </div>
        </div>
        {
          data?.members.length > 0 &&
          <div className="mt-48 mx-auto">
            <div className="flex flex-row mt-8">
              <h1 className="text-xl font-bold w-6/12">Members</h1>
              {/* <h1 className="text-right w-6/12">
                <Link className="hover:text-slate-900 hover:underline text-xl text-slate-700 font-medium" to='/drops'>View All</Link>
              </h1> */}
            </div>
            <div className="divider mt-4"></div>
            <div className="w-full">
              <div className="mx-auto lg:w-8/12 w-full">
                <MemberTable creativeUUID={data && data.uuid} />
              </div>
            </div>
          </div>
        }
        {
          memberOfAPI.data?.results.length > 0 &&
          <div className="mt-48 mx-auto">
            <div className="flex flex-row mt-8">
              <h1 className="text-xl font-bold w-6/12">Member Of</h1>
              {/* <h1 className="text-right w-6/12">
                <Link className="hover:text-slate-900 hover:underline text-xl text-slate-700 font-medium" to='/drops'>View All</Link>
              </h1> */}
            </div>
            <div className="divider mt-4"></div>
            <div className="w-full">
              <div className="mx-auto lg:w-8/12 w-full">
                <MemberOfTable creativeUUID={data && data.uuid} />
              </div>
            </div>
          </div>
        }
        <div className="mt-48 mx-auto">
          <div className="flex flex-row mt-8">
            <h1 className="text-xl font-bold w-6/12">Wristband Drops</h1>
            <h1 className="text-right w-6/12">
              <Link className="hover:text-slate-900 hover:underline text-xl text-slate-700 font-medium" to='/drops'>View All</Link>
            </h1>
          </div>
          <div className="divider mt-4"></div>
          <div className="mx-auto">
            <DropList />
          </div>
        </div>
        <div className="mt-48 mx-auto">
          <div className="flex flex-row mt-8">
            <h1 className="text-xl font-bold w-6/12">Community</h1>
            <h1 className="text-right w-6/12">
              <Link className="hover:text-slate-900 hover:underline text-xl text-slate-700 font-medium" to={data ? `/forums/${data.forum_uuid}` : ''}>View All</Link>
            </h1>
          </div>
          <div className="divider mt-4"></div>
          <ForumDetailHOC forum={data && data.forum} />
        </div>
      </div>

    </div>
  )
}
  
  export default CreativeDetail;
  