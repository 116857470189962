import { Link } from "react-router-dom"
export default function LoginToComment({text}) {
  return (
    <div className="flex flex-row shadow-xl border-2 border-slate-500 w-full rounded-md p-4">
      <div className="my-auto flex w-4/12 content-center">{text}</div>
      <div className="flex w-8/12 justify-end">
        <Link to="/login"><button className="btn btn-primary">Login</button></Link>
      </div>
    </div>
  )
}