export default function NFTOwnerStats({ total, distinct }) {
  return (
    <div className="flex flex-row gap-4">
      <div className="stats  shadow">
        <div className="stat">
          <div className="stat-title">Total Owned</div>
          <div className="stat-value">{ total }</div>
          {/* <div className="stat-desc">Jan 1st - Feb 1st</div> */}
        </div>
      </div>
      <div className="stats  shadow">
        <div className="stat">
          <div className="stat-title">Creatives Supported</div>
          <div className="stat-value">{ distinct }</div>
          {/* <div className="stat-desc">↗︎ 400 (22%)</div> */}
        </div>
      </div>
    </div>
  )
}