import React, { useState } from 'react'


function GenericSearchableList(props) {
  const [search, setSearch] = useState('')
  return (
    <div className="flex h-full justify-center mt-8">
      <div className="flex-col px-4 sm:px-16 pb-4 w-full "> 
        <div className="flex flex-row items-baseline"> 
          <h1 className="text-xl font-bold w-4/12 sm:w-2/12">{props.title}</h1>
          <input
            value={search}
            onChange={ (event) => setSearch(event.target.value) }
            type="text"
            placeholder="Search"
            className="input input-md w-8/12"></input>
        </div>
        <div className="divider mb-4 mt-0"></div>
        {React.cloneElement(props.children, { query: search })}
      </div>
    </div>
  )
}

export default GenericSearchableList;
  