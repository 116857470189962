import React from 'react';
import { useNavigate } from "react-router-dom";

import GenericCardList from '../../../components/GenericCardList'
import useApi from '../../../hooks/useApi'

import { normalizeData, simpleSearchData } from '../../../utils/dataHelpers';

function CreativeList(props) {
  const query = props.query
  const navigate = useNavigate();
  const opts = {
    method: 'GET',
  };
  const { loading, data } = useApi(
    'creatives/',
    opts
  );

  const filteredData = simpleSearchData(data, query)

  const creatives = normalizeData(filteredData, 'uuid');
  const keyMap = {
    title: 'name',
    body: 'bio',
    image: 'image',
    avatars: 'members',
  }
  return (
    <GenericCardList
      loading={loading}
      keyMap={keyMap}
      data={creatives}
      onClick={(uuid) => navigate(`/creatives/${uuid}`)} />
  )
}

export default React.memo(CreativeList);
