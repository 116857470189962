import { useRootComments, useCommentVotes } from "../../../../../hooks/comments";
import EmptyCassette from "../../../../../components/EmptyCassette";
import RootCommentList from "./RootCommentList";
import { useAuth } from "../../../../../hooks/useAuth";


function RootCommentListHOC({forumUUID, order, userUUID}) {
  const { isLoading, data } = useRootComments(forumUUID, order, userUUID);
  const { authenticated } = useAuth();
  const commentVotesAPI = useCommentVotes(
    data && data.results.map(comment => comment.uuid),
    authenticated
  );
  return (
    (isLoading || !data || !data.count) ?
    <EmptyCassette />
    : <RootCommentList comments={data.results} votes={commentVotesAPI.data}/>
  )
}
  
  export default RootCommentListHOC;
  