import { useMe, useEditMe } from "../../../hooks/users";
import { useState } from "react";
import CryptoLogin from "../../login/CryptoLogin";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useAuth } from "../../../hooks/useAuth";

export default function EditProfile() {
  const navigate = useNavigate();
  const { authenticated } = useAuth()
  const meAPI = useMe(authenticated)
  const editMeMutation = useEditMe();
  const [handle, setHandle] = useState(meAPI.data?.handle || '')
  const [name, setName] = useState(meAPI.data?.full_name || '')
  const [bio, setBio] = useState(meAPI.data?.bio || '')
  const [errorMsg, setErrorMsg] = useState('');
  const queryClient = useQueryClient() 

  function cryptoOnSuccess() {
    queryClient.invalidateQueries('me')
    navigate('/me')
  }

  function cryptoOnError() {
    setErrorMsg('Could not connect wallet, try later');
  }

  function resetErrorMsg() {
    setErrorMsg('')
  }

  function getKeyOrEmptyString(obj, key) {
    if (!obj.data) return ''
    if (!obj.data[key]) return ''
    return obj.data[key]
  }

  function submit() {
    let formData = new FormData();
    if (name.trim() !== '') formData.append('full_name', name);
    if (bio.trim() !== '') formData.append('bio', bio);
    if (handle.trim() !== '') formData.append('handle', handle);
    editMeMutation.mutate(formData)
  }
  

  return (
    <div className="flex flex-col bg-white h-fit border-2 border-slate-500 w-full rounded-md p-4 lg:w-8/12">
      <form onClick={resetErrorMsg} className="flex flex-col gap-8">
        <div className="form-control w-full">
          <label className="label">
            <span className="label-text">Handle</span>
          </label>
          <input
            onChange={(e) => setHandle(e.target.value)}
            value={handle || getKeyOrEmptyString(meAPI, 'handle')}
            type="text"
            placeholder="Type here"
            className="input input-bordered w-full" />
        </div>
        <div className="form-control w-full">
          <label className="label">
            <span className="label-text">What is your name?</span>
          </label>
          <input
            onChange={(e) => setName(e.target.value)}
            value={name || getKeyOrEmptyString(meAPI, 'full_name')}
            type="text"
            placeholder="Type here"
            className="input input-bordered w-full" />
        </div>
        <div className="form-control w-full">
          <label className="label">
            <span className="label-text">Your bio</span>
          </label> 
          <textarea
            onChange={(e) => setBio(e.target.value)}
            value={bio || getKeyOrEmptyString(meAPI, 'bio')}
            className="textarea textarea-bordered h-24" placeholder="Bio"></textarea>
        </div>
        
        {
          meAPI.data && !meAPI.data?.wallets.length &&
            <CryptoLogin 
              text="Connect your wallet"
              onSuccess={cryptoOnSuccess}
              onError={cryptoOnError}
              stytch_id={meAPI.data.stytch_id}/>
        } 
       
        {
          editMeMutation.isLoading ?
          <button className="btn btn-primary loading"></button>
          : <button onClick={submit} className="btn btn-primary">Save</button>
        }
      </form>
      {
        errorMsg !== '' &&
        <div className="alert alert-error shadow-lg">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
            <span>Error! Task failed successfully.</span>
          </div>
        </div>
      }
    </div>
  )
}