import EmptyCassette from "../../../components/EmptyCassette";
import ForumDetail from "./ForumDetail";
import { useParams } from "react-router-dom";
import { useRootComments } from "../../../hooks/comments";
import WriteRootComment from "../routes/comments/components/WriteRootComment";
import { useForum } from "../../../hooks/forums";
import { useAuth } from "../../../hooks/useAuth";
import LoginToComment from "../../../components/LoginToComment";
import { useHasForumAccess } from "../../../hooks/forums";
import MintToComment from "../../../components/MintToComment";

function ForumDetailHOC(props) {
  const {authenticated} = useAuth();
  let params = useParams();
  const forumUUID = params.forumUUID
  const forumAPI = useForum(forumUUID);
  const rootCommentAPI = useRootComments(forumUUID);
  const hasAccessAPI = useHasForumAccess(forumAPI.data && forumAPI.data.url)

  return (
    (rootCommentAPI.isLoading || !rootCommentAPI.data || forumAPI.isLoading || !forumAPI.data) ?
    <EmptyCassette />
    : <div className="flex h-full w-full justify-center">
        <div className="flex-col h-fit w-full space-y-8 px-4 sm:px-16 pb-4 lg:w-8/12">
          {
            authenticated ?
              hasAccessAPI.isSuccess ?
                <WriteRootComment
                  rootComment={null}
                  replyingToComment={null}
                  forum={forumAPI.data} />
              : <MintToComment to={"/drops"} text={ 'You must hold one of the artists NFTs to comment.' }/>
            : <LoginToComment text={'You must be logged in to post'}/>
          }
          <ForumDetail forum={forumAPI.data} />
        </div>
      </div>
    
  )
}
  
  export default ForumDetailHOC;
  