
  import { Outlet } from "react-router-dom";

  function ProfilePage() {
    return (
      <div className="px-4 py-4 sm:py-4 sm:px-8">
        <Outlet />
      </div>
    )
  }
  
  export default ProfilePage;
  