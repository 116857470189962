import { BigNumber } from 'ethers'

export function transformTokenInformation(data) {
  if (!data) {
    return undefined
  }
  const distinctCreatives = {};
  const transformedTokenInfo = data.ownedNfts.map(
    (nft) => {
      distinctCreatives[nft.contract.address] = true;
      const tokenID = BigNumber.from(nft.id.tokenId);
      const dropID = tokenID.shr(128);
      return {
        dropID: dropID.toString(),
        tokenID: tokenID.toString(),
        tokenURI: nft.tokenUri.raw.replace('{id}', tokenID.toString())
      }
    }
  )
  data.transformedTokenInfo = transformedTokenInfo
  data.distinctCreativeCount = Object.keys(distinctCreatives).length;
  return data
}