import GenericSearchableList from "../../../components/GenericSearchableList";
import EventList from "./EventList";

function AllEvents() {
  return (
    <GenericSearchableList title="Events">
      <EventList />
    </GenericSearchableList>
  )
}

export default AllEvents;
  