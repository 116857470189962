import React from 'react';
import { useNavigate, useParams } from "react-router-dom";

import GenericCardList from '../../../components/GenericCardList'
import useApi from '../../../hooks/useApi'

import { normalizeData, simpleSearchData } from '../../../utils/dataHelpers';

function EventList(props) {
  const query = props.query
  const navigate = useNavigate();
  const params = useParams();
  const dropUUID = params.dropUUID
  const opts = {
    method: 'GET',
    params: {
      drop__uuid: dropUUID,
  }
  };
  const { loading, data } = useApi(
    'events/',
    opts
  );
  const filteredData = simpleSearchData(data, query)

  const events = normalizeData(filteredData, 'uuid');
  const keyMap = {
    title: 'name',
    body: 'description',
    image: 'image', 
  }
  return (
    <GenericCardList
      loading={loading}
      keyMap={keyMap}
      data={events}
      onClick={(uuid) => navigate(`/events/${uuid}`)} />
  )
}

export default React.memo(EventList);
