import { useState } from 'react';

function EmptyCassette() {
  const [play, setPlay] = useState(false)
  return (
    <div className="flex flex-col flex-wrap content-center justify-center w-6/12 mx-auto my-8">
      <h1 className="text-3xl font-medium text-center mb-12">{play ? "Playing side b" : "Empty Cassette"}</h1>
      <img onClick={() => setPlay(!play)} alt="404" className={"mx-auto " + (play && "animate-spin") } src="/404.png" />
      {
        play && <iframe title="emptyCassette" className="hidden" width="100%" height="20" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1071122107&color=%23ff5500&inverse=false&auto_play=true&show_user=true">
                </iframe>
                
      }
    </div>
  )
}

export default EmptyCassette;
