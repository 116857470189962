import { DateTime } from "luxon";
import { useEffect, useState } from "react";
export default function AuctionEndCountdown({ end }) {
  const endTime = DateTime.fromISO(end)
  const [units, setUnits] = useState(['00', '00', '00', '00'])
  useEffect(() => {
    const interval = setInterval(() => {
      const diffNow = endTime.diffNow('seconds');
      const unitSplit = diffNow.toFormat('dd:hh:mm:ss').split(':')
      setUnits(unitSplit)

    }, 1000);
    return () => clearInterval(interval);
  });

  return (
    <div className="grid grid-flow-col gap-5 text-center auto-cols-max justify-center md:justify-start">
      <div className="flex flex-col">
        <span className="countdown font-mono text-xl">
          <span style={{'--value':units[0]}}></span>
        </span>
        days
      </div> 
      <div className="flex flex-col">
        <span className="countdown font-mono text-xl">
          <span style={{'--value':units[1]}}></span>
        </span>
        hours
      </div> 
      <div className="flex flex-col">
        <span className="countdown font-mono text-xl">
          <span style={{'--value':units[2]}}></span>
        </span>
        min
      </div> 
      <div className="flex flex-col">
        <span className="countdown font-mono text-xl">
          <span style={{'--value':units[3]}}></span>
        </span>
        sec
      </div>
    </div>
  )
}