import axios from "axios";
import {
  useQuery,
} from "react-query";

const ALCHEMY_URL = process.env.REACT_APP_CHAIN_URL

// const axiosInstance = axios.create({
//   withCredentials: true
// })


const getNFTs = async (ownerAddress, contractAddresses) => {
  const { data } = await axios.get(
    `${ALCHEMY_URL}/getNFTs`,
    {
      params: {
        owner: ownerAddress,
        contractAddresses: contractAddresses
      }
    }
  );
  return data;
};

export function useNFTs(ownerAddress, contractAddresses) {
  return useQuery(["nfts", ownerAddress, contractAddresses], () => getNFTs(ownerAddress, contractAddresses), {
    enabled: !!ownerAddress && !!contractAddresses,
  });
}