import NavBar from '../components/NavBar';
import { Outlet } from "react-router-dom";

function MainLayout() {
  return (
    <div className="min-h-screen">
      <NavBar />
      <Outlet />
    </div>
  )
}

export default MainLayout;
