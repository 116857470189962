import { useState } from 'react'
import { useEthers } from '@usedapp/core'
// import useApi from '../../useApi'

const REACT_APP_EQ_API_DOMAIN = process.env.REACT_APP_EQ_API_DOMAIN
const authUrl = `${REACT_APP_EQ_API_DOMAIN}stytch/`

export default function CryptoLogin({ text, stytch_id, onSuccess, onError }) {
  const { activateBrowserWallet, account, library, error } = useEthers()
  const [authStarted, setAuthStarted] = useState(false);
  const [connectionStarted, setConnectionStarted] = useState(false);

  function removeModal() {
    setAuthStarted(false)
    setConnectionStarted(false)
  }

  async function authenticateWalletPrep() {
    activateBrowserWallet()
    setConnectionStarted(true)
  }

  async function authenticateWallet(e) {
    e.stopPropagation();
    setAuthStarted(true)
    const startResponse = await startWalletAuth()
    if (startResponse.ok) {
      const startResponseData = await startResponse.json()
      const challenge = startResponseData.challenge
      const user_id = startResponseData.user_id
      const finishResponse = await finishWalletAuth(challenge, user_id)
      if (!finishResponse) {
        return;
      }
      else if (finishResponse.ok) {
        onSuccess()
      } else {
        onError()
      }
    }
  }

  async function startWalletAuth() {
    try {
      const response = await fetch(authUrl, {
        method: "POST",
        body: JSON.stringify({
          crypto_wallet_address: account,
          user_id: stytch_id,
        }),
        headers: {
          "Content-Type": "application/json",
        },
        credentials: 'include'
      });
      return response
    } catch (err) {
      console.log(err)
    }
  }

  async function finishWalletAuth(challenge) {
    const signer = library.getSigner()
    let signature;
    try {
      signature = await signer.signMessage(challenge)
    } catch (err) {
      setAuthStarted(false);
      return;
    }
    
    try {
      const response = await fetch(authUrl, {
        method: "POST",
        body: JSON.stringify({
          crypto_wallet_address: account,
          crypto_wallet_signature: signature,
        }),
        headers: {
          "Content-Type": "application/json",
        },
        credentials: 'include'
      });
      return response
    } catch (err) {
      console.error("Error authenticating magic link");
    }
  }

  return (
    <div>
      <button className="w-full normal-case btn btn-primary text-lg font-medium rounded" onClick={() => authenticateWalletPrep()}>
        <div className="avatar mr-auto hidden sm:block">
          <div className="w-5 bg-white rounded-full ring ring-white">
            <img className="w-4 h-4" src="/metamask-fox.svg" alt="metamask"></img>
          </div>
        </div>
        <span className="mr-auto sm:-ml-4">{text}</span>
        {/* <label for="my-modal-6" className="btn modal-button hidden">open modal</label> */}
      </button>

      <div>
        <input type="checkbox" id="my-modal-6" className="modal-toggle" checked={connectionStarted || authStarted} onChange={()=> void(0)}/>
        <div className="modal modal-bottom sm:modal-middle" onClick={removeModal}>
          {
            !account ? 
              <div className="modal-box relative" onClick={(e) => e.stopPropagation()}>
                <label onClick={removeModal} className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                <h3 className="font-bold text-lg">Connect your Wallet</h3>
                <p className="py-4">You should see a pop up that will allow you to connect your wallet</p>
                {
                  error?.code === 4001 &&
                  <button 
                    onClick={() => authenticateWalletPrep()}
                    className="btn btn-primary">Connect your wallet
                  </button>
                }
              </div>

            : <div className="modal-box relative" onClick={(e) => e.stopPropagation()}>
                <label onClick={removeModal} className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                <h3 className="font-bold text-lg">Verify your Wallet</h3>
                <p className="py-4">When you click verify, you will need to sign a message in your wallet. This will allow us to sign you in.</p>
                <div className="modal-action">
                  {
                    !authStarted ?
                      <button 
                        onClick={async (e) => await authenticateWallet(e)} 
                        className="btn btn-primary">Verify your wallet
                      </button>
                    : <button 
                        className="btn btn-primary loading">
                        Verify your wallet
                      </button>
                  }
                </div>
              </div>
          }
        </div>
      </div>
    </div>
  )
}