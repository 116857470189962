import { useState } from 'react';
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from "rehype-sanitize";
import { useCreateComment } from '../../../../../hooks/comments';
import remarkGemoji from 'remark-gemoji'
import validator from 'validator';

function isCommentValid(value, titleValue, linkValue) {
  if (validator.trim(value) === '') {
    return false
  }
  if (validator.trim(titleValue) === '') {
    return false
  }

  const linkValueClean = validator.trim(linkValue)
  if (linkValueClean !== '' && !validator.isURL(linkValueClean)) {
    return false
  }
  return true
}

function WriteComment({ rootComment, replyingToComment, forum }) {
  const [value, setValue] = useState("");
  const [titleValue, setTitleValue] = useState("");
  const [linkValue, setLinkValue] = useState("");
  
  let disabled = true;
  if (isCommentValid(value, titleValue, linkValue)) {
    disabled = false;
  }

  const writeCommentMutation = useCreateComment()

  async function createComment() {
    const payload = {
      title: titleValue,
      link: linkValue,
      text: value,
      forum: forum.url,
      forumUUID: forum.uuid,
      root_comment: null,
      replying_to: null,
    }

    try {
      await writeCommentMutation.mutateAsync(payload)
      setValue('');
      setTitleValue('');
      setLinkValue('');
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className="border-2 border-slate-500 w-full rounded-md p-4">
      <div className="form-control w-full">
        <label className="label">
          <span className="label-text">Title</span>
        </label>
        <input
          value={titleValue}
          onChange={event => setTitleValue(event.target.value)}
          type="text"
          placeholder="Title"
          className="input input-bordered sm:w-6/12 mb-2" />
        <label className="label">
          <span className="label-text">Link</span>
        </label>
        <input
          value={linkValue}
          onChange={event => setLinkValue(event.target.value)}
          type="text"
          placeholder="Link (optional)"
          className="input input-bordered w-full mb-6" />
      </div>
      
      <MDEditor
        value={value}
        onChange={setValue}
        preview={'edit'}
        previewOptions={{
          skipHtml: true,
          rehypePlugins: [[rehypeSanitize]],
          remarkPlugins: [[remarkGemoji]]
        }}
      />
      <div className="mt-2 text-right">
        {
          writeCommentMutation.isLoading ?
          <button className="btn btn-primary loading"></button>

          : <button
              onClick={ () => createComment() }
              className="btn btn-primary"
              disabled={ disabled }>
                Post
            </button>
        }
      </div>
    </div>
  );
}
export default WriteComment;
  