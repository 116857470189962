import { useState } from "react";
import {
  useNavigate,
  useParams,
} from "react-router-dom";

import DDWAbi from '../../../abis/ddw_abi.json'
import { utils } from 'ethers'
import { Contract } from '@ethersproject/contracts'
import {
  useCall,
  useEthers,
} from '@usedapp/core'

import useApi from '../../../hooks/useApi'
import { useAuth } from '../../../hooks/useAuth'
import GenericCard from "../../../components/GenericCard";


const ddWInterface = new utils.Interface(DDWAbi)
const ddWContractAddress = process.env.REACT_APP_CONTRACT_ADDRESS
const contract = new Contract(ddWContractAddress, ddWInterface)

function EventDetail(props) {
  const { account } = useEthers();
  const [code, setCode] = useState();
  const {authenticated} = useAuth();
  let params = useParams();
  const eventUUID = params.eventUUID
  const navigate = useNavigate()
  const opts = {
    method: 'GET',
  };
  const { loading, data } = useApi(
    `events/${eventUUID}/`,
    opts
  );

  const call = {
    contract,
    method: 'belongsToDrop',
    args: [account, data && data.drop__uuid_to_hex]
  }
  const { value } = useCall(call) ?? { value: false, error: undefined}
  const belongsToDrop = value && value[0];

  let indicator = {
    text: belongsToDrop ? "Owned" : "Not Owned",
    yes: belongsToDrop,
  };
  
  const keyMap = {
    title: 'name',
    body: 'description',
    image: 'image'
  }

  async function claimCode() {
    const url = `${data.url}claim_code/`
    try {        
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: 'include'
      });
      const responseData = await response.json();
      setCode(responseData.code)
    } catch (err) {
      console.error("Could not claim Code");
    }
  }

  return (
    <div className="flex h-full justify-center">
      <div className="flex-col w-full space-y-8 px-4 sm:px-16 pb-4">
        <h1 className="text-center text-3xl lg:text-5xl font-bold">
          {data && data.name}
        </h1>
        <div className="flex flex-col md:flex-row space-y-12 lg:space-y-0 lg:space-x-12 md:mx-auto lg:w-full ">
          <div className="flex md:w-6/12 justify-center">
            <GenericCard
              loading={loading}
              data={data}
              keyMap={keyMap}
              indicator={indicator}
              // onClick={() => onClick(data[k].uuid)}
              />
          </div>
          <div className="flex flex-col flex-wrap prose md:w-6/12 justify-center md:justify-start text-center md:text-left">
            <div className="sm:flex sm:flex-row sm:w-full gap-4">
              <div className="sm:w-6/12">
                <h2 className="mb-0">Event:</h2>
                <p className="m-0">{data && data.name}</p>
              </div>
              <div className="sm:w-6/12">
                <h2 className="mb-0">Event Date:</h2>
                <p>{data && new Date(data.start_datetime).toDateString()} - {data && new Date(data.end_datetime).toDateString()}</p>
              </div>
            </div>
            <div className="w-full">
              <h2 className="my-0">Description:</h2>
              <p className="m-0">{data && data.description}</p>
            </div>
            <div className="w-full my-4">

              {
                authenticated ?
                  !code ?
                    belongsToDrop ?
                      <button className="btn btn-primary" onClick={claimCode}>Reveal Code</button>
                    : <button className="btn btn-primary">Buy Now</button>
                  : <div>
                      <h2>Code:</h2>
                      <span>{code}</span>
                    </div>
                : <button className="btn btn-primary" onClick={() => navigate('/login')}>Login</button>

              }
              
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
  
  export default EventDetail;
  