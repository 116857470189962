import { useMemberOf } from "../../../hooks/creatives"
import AddressBadge from "../../../components/AddressBadge"
import { Link } from "react-router-dom"

export default function MemberOfTable({creativeUUID}) {
  const { data } = useMemberOf(creativeUUID)
  return (
    <div className="overflow-x-auto w-full">
      <table className="table w-full">
        <thead>
          <tr>
            <th>Community Name</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            data?.results.map(
              (creative) => (
                <tr key={creative.uuid}>
                  <td>
                    <div className="flex items-center space-x-3">
                      <div className="avatar">
                        <div className="mask mask-hexagon-2 w-12 h-12">
                          <img src={creative.image} alt="Avatar Tailwind CSS Component" />
                        </div>
                      </div>
                      <div>
                        <Link className="hover:text-violet-500" to={`/creatives/${creative.uuid}`}>
                          <div className="font-bold">{creative.name}</div>
                        </Link>
                        <div className="text-sm opacity-50"><AddressBadge address={creative.contract_address} linkOut={true} /></div>
                      </div>
                    </div>
                  </td>
                  <td className="pl-0 pr-2">
                    <Link className="hover:text-violet-500" to={`/forums/${creative.forum_uuid}`}>
                      <button className="btn btn-ghost btn-xs">forum</button>
                    </Link>
                  </td>
                </tr>
              )
            )
          }
          
          
        </tbody>
      </table>
    </div>
  )
}