import { useState, useEffect } from "react";
import {
  Link,
  useNavigate,
} from "react-router-dom";
import CommentIcon from "../../../../../components/icons/CommentIcon";
import UpVoteIcon from "../../../../../components/icons/UpVoteIcon";
import { getEmbed } from "../../../../../utils/linkHelpers";
import ReactMarkdown from 'react-markdown'
import remarkGemoji from 'remark-gemoji'
import { DateTime } from "luxon";
import { useVoteOnComment } from "../../../../../hooks/comments";
import { useAuth } from "../../../../../hooks/useAuth";
import { useHasForumAccess } from "../../../../../hooks/forums";

function RootComment({comment, vote}) {
  const {authenticated} = useAuth();
  const hasAccessAPI = useHasForumAccess(comment.forum);

  const [voteState, setVoteState] = useState(vote)
  const navigate = useNavigate();
  const voteMutation = useVoteOnComment(comment.url);

  const buttonsDisabled =  !authenticated || !hasAccessAPI.data?.has_access;
  const embed = getEmbed(comment.link, comment.title, {width: "100%", height: "100%"})

  function upVoteComment() {
    const commentUUID = comment.uuid;
    const newVote = voteState > 0 ? -1 : 1 
    voteMutation.mutate({
      commentUUID: commentUUID,
      vote: newVote,
    })
    comment.score += newVote;
    setVoteState(newVote);
  }

  useEffect(() => {
    setVoteState(vote);
  }, [vote])

  return (
    <div className="shadow-xl border-2 border-slate-500 w-full rounded-md p-4">
      <Link to={`/users/${comment.user.uuid}`}><span className="text-sm font-bold">{comment.user.handle}</span></Link>
      <span className="text-sm font-light">
        <span className="text-xs font-light"> • </span>
        { DateTime.fromISO(comment.created).toRelative() }
      </span>
      <div className="prose w-full max-w-none">
        <Link className=" hover:text-slate-900 hover:underline text-xl text-slate-700 font-medium" to={`/comments/${comment.uuid}`}>
          <h3 className="my-0">
            { comment.title }
          </h3>
        </Link>
        <ReactMarkdown children={comment.text} remarkPlugins={[remarkGemoji]} />
        {
          embed !== undefined ?
            <div className="h-96 sm:h-128 w-full">
              {embed}
            </div>
          : <a className="text-xs" href={comment.link} target="_blank" rel="noreferrer">{comment.link}</a>
        }
        <div className="flex flex-row w-full mt-2">
          <div>
            <UpVoteIcon disabled={buttonsDisabled} text={comment.score} action={() => upVoteComment()} filled={voteState > 0}/>
          </div>
          <div>
            <CommentIcon disabled={buttonsDisabled} text={comment.comment_count} action={() => navigate(`/comments/${comment.uuid}`)}/>
          </div>
        </div>
      </div>
    </div>
  )
}
export default RootComment;
  