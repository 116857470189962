export default function AddressBadge({ address, linkOut }) {
  const polygonScanURL = `${process.env.REACT_APP_POLYGONSCAN}address/${address}`
  let humanAddress = '0x0';
  if (address) {
    const part1 = address.substring(0, 5);
    const part2 = '...';
    const part3 = address.substring(address.length - 4);
    humanAddress = part1 + part2 + part3;
  }
  
  return (
    !linkOut ?
      <span onClick={() => address && navigator.clipboard.writeText(address)} className="badge cursor-pointer hover:bg-slate-300">
        {humanAddress}
        <svg xmlns="http://www.w3.org/2000/svg" className="ml-1 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
        </svg>
      </span>
    : <a target="_blank"
        rel="noreferrer"
        href={polygonScanURL}>
        <span onClick={() => address && navigator.clipboard.writeText(address)} className="badge cursor-pointer hover:bg-violet-500 hover:border-violet-500">
          {humanAddress}
          <svg xmlns="http://www.w3.org/2000/svg" className="ml-1 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
          </svg>
        </span>
      </a>
  )
}