import { useCreativeURL } from "../hooks/creatives"

export default function Avatar({creativeURL}) {
  const { data } = useCreativeURL(creativeURL)
  return (
    <div className="avatar">
      <div className="w-12 bg-slate-300">
        {
          data ? 
            <img src={data.image} alt={data.name}/>
          : <div className="animate-pulse w-full h-full bg-slate-700"></div> }
      </div>
    </div>
  )
}