import axios from "axios";
import {
  useQuery,
  useMutation,
  useQueryClient
} from "react-query";

const REACT_APP_EQ_API_DOMAIN = process.env.REACT_APP_EQ_API_DOMAIN
const REACT_APP_EQ_API_ROOT = process.env.REACT_APP_EQ_API_ROOT

const axiosInstance = axios.create({
  withCredentials: true
})

const getUserByURL = async (url) => {
  const { data } = await axiosInstance.get(
    `${url}`
  );
  return data;
};

const getUser = async (userUUID) => {
  const { data } = await axiosInstance.get(
    `${REACT_APP_EQ_API_DOMAIN}${REACT_APP_EQ_API_ROOT}users/${userUUID}/`
  );
  return data;
};

const getMe = async () => {
  const { data } = await axiosInstance.get(
    `${REACT_APP_EQ_API_DOMAIN}${REACT_APP_EQ_API_ROOT}users/me/`
  );
  return data;
};

const editMe = async (payload) => {
  const { data } = await axiosInstance.patch(
    `${REACT_APP_EQ_API_DOMAIN}${REACT_APP_EQ_API_ROOT}users/me/`,
    payload,
    {
      headers: {
      'content-type': 'multipart/form-data',
      }
    }
  );
  return data;
};

const logout = async () => {
  const { data } = await axiosInstance.post(
    `${REACT_APP_EQ_API_DOMAIN}stytch_logout/`,
  );
  return data;
};

export function useEditMe() {
  const queryClient = useQueryClient()
  return useMutation(
    (payload) => editMe(payload),
    {
      // Always refetch after error or success:
      onSettled: (variables) => {
        queryClient.invalidateQueries('me')
      },
    },
  );
}

export function useUserURL(userURL) {
  return useQuery(["user", {userURL}], () => getUserByURL(userURL), {
    enabled: !!userURL,
  });
}

export function useUser(userUUID) {
  return useQuery(["user", {userUUID}], () => getUser(userUUID), {
    enabled: !!userUUID,
  });
}

export function useMe(authenticated) {
  return useQuery(["me"], () => getMe(), {
    enabled: authenticated,
  });
}

export function useLogout(url) {
  const queryClient = useQueryClient()
  return useMutation(
    (payload) => logout(url, payload),
    {
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.removeQueries('me')
      },
    },
  );
}