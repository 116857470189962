
  import { Outlet } from "react-router-dom";

  function CommentPage() {
    return (
      <div className="">
        <Outlet />
      </div>
    )
  }
  
  export default CommentPage;
  