import { Link } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { useMe } from '../hooks/users';

export default function NavBarProfileItem({text}) {
  const { authenticated } = useAuth()
  const { isLoading, data } = useMe(authenticated);

  function getHandle() {
    if (isLoading) {
      return ''
    }
    return data.handle[0] || 'u'
  }

  function getImage() {
    if (isLoading) {
      return undefined
    }
    return data.image
  }

  return (
    <Link to={data ? `/users/${data.uuid} `: ''}>
      <li>
        <div className="avatar placeholder">
          <div className="bg-neutral-focus text-neutral-content rounded-full w-8">
            { getImage() ? <img src={getImage()} alt={getHandle()} /> : <span>{ getHandle() }</span>}
          </div>
          <span>{text}</span>
        </div>
        
      </li>
    </Link>
  )
}