import axios from "axios";
import {
  useQuery,
} from "react-query";
const REACT_APP_EQ_API_DOMAIN = process.env.REACT_APP_EQ_API_DOMAIN
const REACT_APP_EQ_API_ROOT = process.env.REACT_APP_EQ_API_ROOT

const axiosInstance = axios.create({
  withCredentials: true
})

// const getForumByID = async (id) => {
//   const { data } = await axios.get(
//     `${REACT_APP_EQ_API_DOMAIN}${REACT_APP_EQ_API_ROOT}forums/${id}`
//   );
//   return data;
// };

const getForumByURL = async (url) => {
  const { data } = await axiosInstance.get(
    `${url}`
  );
  return data;
};

const getForumByUUID = async (uuid) => {
  const { data } = await axiosInstance.get(
    `${REACT_APP_EQ_API_DOMAIN}${REACT_APP_EQ_API_ROOT}forums/${uuid}`
  );
  return data;
};

const hasForumAccess = async (url) => {
  const { data } = await axiosInstance.get(
    `${url}has_access/`
  );
  return data;
};

export function useHasForumAccess(forumURL) {
  return useQuery(["hasAccess", forumURL],() => hasForumAccess(forumURL), {
    enabled: !!forumURL,
  });
}

export function useForum(forumUUID) {
  return useQuery(["forum", forumUUID], () => getForumByUUID(forumUUID), {
    enabled: !!forumUUID,
  });
}

export function useForumURL(forumURL) {
  return useQuery(["forum", forumURL], () => getForumByURL(forumURL), {
    enabled: !!forumURL,
  });
}