import { Stytch } from "@stytch/stytch-react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

import CryptoLogin from "./CryptoLogin";
// import useApi from '../../useApi'

const STYTCH_PUBLIC_TOKEN = process.env.REACT_APP_STYTCH_PUBLIC_TOKEN;
const REACT_APP_EQ_CLIENT_DOMAIN = process.env.REACT_APP_EQ_CLIENT_DOMAIN

const Login = () => {
  const { setAuthenticated } = useAuth();
  const navigate = useNavigate();

  function cryptoOnSuccess() {
    setAuthenticated(true);
    navigate("/");
  }

  function cryptoOnError() {
    navigate("/");
  }

  const stytchProps = {
    loginOrSignupView: {
      products: ['emailMagicLinks'],
      emailMagicLinksOptions: {
        loginRedirectURL: `${REACT_APP_EQ_CLIENT_DOMAIN}authenticate`,
        loginExpirationMinutes:30,
        signupRedirectURL: `${REACT_APP_EQ_CLIENT_DOMAIN}authenticate`,
        signupExpirationMinutes: 30,
      }
    },
    style: {
      fontFamily: 'Poppins',
      width: "93%",
      primaryColor: "rgb(87, 13, 248)",
      primaryTextColor: "#090909",
      hideHeaderText: true,
    },
    publicToken: STYTCH_PUBLIC_TOKEN,
    // callbacks: {
    //   onEvent: (data) => {
    //     if (data.eventData.type === "USER_EVENT_TYPE") {
    //       fetch(`http://localhost:3000/users`, {
    //         method: "POST",
    //         body: JSON.stringify({
    //           userId: data.eventData.userId,
    //           email: data.eventData.email,
    //         }),
    //         headers: {
    //           "Content-Type": "application/json",
    //         },
    //       });
    //     }
    //   },
    //   onSuccess: (data) => console.log(data),
    //   onError: (data) => console.log(data),
    // },
  };

  return (
    <div className="border-slate-800 border-4 container w-full lg:w-96 bg-base-100 p-2 lg:p-6 pt-8 pb-8">
      <h1 className=" mb-6 text-3xl font-medium	lg:text-left">Sign up or Login</h1>
      <Stytch
        publicToken={stytchProps.publicToken}
        loginOrSignupView={stytchProps.loginOrSignupView}
        style={stytchProps.style}
      />
      <div className="divider">OR</div>
      <CryptoLogin 
        text="Continue with wallet" 
        onSuccess={cryptoOnSuccess}
        onError={cryptoOnError}/>
    </div>
  );
};

export default Login;