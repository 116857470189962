import creativeABI from '../abis/creative_abi.json'
import { utils } from 'ethers'
import { Contract } from '@ethersproject/contracts'
import {
  useCall,
  useEthers,
} from '@usedapp/core'

function BelongsToDropIndicator(props) {
  const { account } = useEthers();
  const creativeInterface = new utils.Interface(creativeABI)
  const creativeContractAddress = props.contractAddress
  const contract = new Contract(creativeContractAddress, creativeInterface)
  
  const call = {
    contract: contract,
    method: 'numNFTTokenIDsInDropOwned',
    args: [account, props.dropID]
  }
  
  const { value } = useCall(call) ?? { value: 0, error: undefined}
  const belongsToDrop = value && value[0];
  return (
    <div>
      {
        (belongsToDrop !== 0 && !belongsToDrop.isZero()) ?
          <span className="indicator-item badge badge-primary">Owned</span>
        : <span className="indicator-item badge">Not Owned</span>
      }
    </div>
  )
}

export default BelongsToDropIndicator