import { DateTime } from "luxon";

function finalSort(roots, order) {
  if (order === '-score') {
    roots.sort((a, b) => {
      return b.score - a.score;
    })
  }
  return roots
}


export function generateCommentTree(comments, rootComment, includeRootComment, order) {
  const sortedComments = comments.filter(
    (comment) => {
      if (!includeRootComment) {
        return comment.uuid !== rootComment.uuid;
      }
      return true
    }
  ).sort((a, b) => {
    return DateTime.fromISO(b.created) - DateTime.fromISO(a.created)
  });
  const nodeMap = {};
  let node;
  const roots = [];
  let i;

  for (i = 0; i < sortedComments.length; i += 1) {
    nodeMap[sortedComments[i].url] = i;
    sortedComments[i].replies = [];
  }

  for (i = 0; i < sortedComments.length; i += 1) {
    node = sortedComments[i];
    if (node.replying_to) {
      try {
        sortedComments[nodeMap[node.replying_to]].replies.push(node);
      } catch {
        roots.push(node)
      }
      
    } else {
      roots.push(node)
    }
  }
  return finalSort(roots, order);
}

export function generateVoteHash(votes) {
  if (!votes) {
    return {}
  }
  const voteHash = votes.reduce(
    (prev, curr) => {
      if (prev[curr.comment] === undefined) {
        prev[curr.comment] = curr.vote;
      } else {
        prev[curr.comment] += curr.vote;
      }
      
      return prev;
    },
    {}
  )
  return voteHash;
}