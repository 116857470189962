import Avatar from "./Avatar"

export default function CreativeAvatarGroup({creativeURLs, limit = 4}) {
  const totalURLs = creativeURLs.length
  let additional = totalURLs - limit;
  let avatars;
  if (totalURLs < limit) {
    avatars = [...creativeURLs]
  } else {
    avatars = [...creativeURLs].slice(0, limit)
  }
  return (
    <div className="avatar-group -space-x-6">
      { 
        avatars.map(
          (avatar) => (
            <Avatar key={avatar} creativeURL={avatar}/>
          )
        )
      }
      {
        additional > 0 &&
        <div className="avatar placeholder">
          <div className="w-12 bg-neutral-focus text-neutral-content">
            <span>+{additional}</span>
          </div>
        </div>
      }
    </div>
    // <div className="avatar-group -space-x-6">
    //   <div className="avatar">
    //     <div className="w-12">
    //       <img src="https://api.lorem.space/image/face?hash=4818" />
    //     </div>
    //   </div>
    //   <div className="avatar">
    //     <div className="w-12">
    //       <img src="https://api.lorem.space/image/face?hash=40311" />
    //     </div>
    //   </div>
    //   <div className="avatar">
    //     <div className="w-12">
    //       <img src="https://api.lorem.space/image/face?hash=84348" />
    //     </div>
    //   </div>
    //   <div className="avatar placeholder">
    //     <div className="w-12 bg-neutral-focus text-neutral-content">
    //       <span>+99</span>
    //     </div>
    //   </div>
    // </div>
  )
}