import React from 'react';
import {
  useParams,
  useNavigate,
} from "react-router-dom";

import GenericCardList from '../../../components/GenericCardList'
import useApi from '../../../hooks/useApi'

import { normalizeData, simpleSearchData } from '../../../utils/dataHelpers';

function DropList(props) {
  const query = props.query
  const navigate = useNavigate();
  let params = useParams();
  const creativeUUID = params.creativeUUID
  let paramsOpt = undefined;
  if (creativeUUID) {
    paramsOpt = {
      creative__uuid: creativeUUID,
    }
  }
  const opts = {
    method: 'GET',
    params: paramsOpt,
  };
  const { loading, data } = useApi(
    'drops/',
    opts
  );
  const filteredData = simpleSearchData(data, query)

  const drops = normalizeData(filteredData, 'uuid_to_hex');
  const keyMap = {
    title: 'name',
    body: 'description',
    image: 'image', 
  }
  return (
    <GenericCardList
      loading={loading}
      keyMap={keyMap}
      data={drops}
      onClick={(uuid) => navigate(`/drops/${uuid}`)} />
  )
}

export default React.memo(DropList);
