import './App.css';
import './index.css';
import React from "react";
import { AlchemyProvider } from '@ethersproject/providers'
import { ReactQueryDevtools } from 'react-query/devtools'
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'
import MainLayout from './layouts/MainLayout';
import DropsPage from './routes/drops/DropsPage';
import {
  Mumbai,
  DAppProvider,
} from '@usedapp/core'
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import HomePage from './routes/home/HomePage';
import AllDrops from './routes/drops/components/AllDrops';
import DropDetail from './routes/drops/components/DropDetail';
import CreativePage from './routes/creatives/CreativePage';
import AllCreatives from './routes/creatives/components/AllCreatives';
import CreativeDetail from './routes/creatives/components/CreativeDetail';
import EventPage from './routes/events/EventPage';
import AllEvents from './routes/events/components/AllEvents';
import EventDetail from './routes/events/components/EventDetail';
import CommentPage from './routes/forums/routes/comments/CommentsPage';
import CommentDetailHOC from './routes/forums/routes/comments/components/CommentDetailHOC';
import LoginFlat from './routes/login/LoginFlat';
import Authenticate from './routes/authenticate/Authenticate';
import { StytchContext } from './providers/StytchProvider';
import Cookies from 'js-cookie'
import GenericErrorBoundary from './components/GenericErrorBoundary';
import ForumPage from './routes/forums/ForumPage';
import ProfilePage from './routes/profile/ProfilePage';
import Profile from './routes/profile/components/Profile';
import ForumDetailFullHOC from './routes/forums/components/ForumDetailFullHOC';
// import { RequireAuth } from './components/ProtectedRoute';
import EditProfile from './routes/profile/components/EditProfile';

const config = {
    readOnlyChainId: Mumbai.chainId,
    readOnlyUrls: {
      [Mumbai.chainId]: new AlchemyProvider('maticmum', process.env.REACT_APP_ALCHEMY_ID),
    },
    // pollingInterval: 300000,
}

const queryClient = new QueryClient()

function App() {
  
  const has_session_jwt = !!Cookies.get('eq_logged_in') ? true : false

  const [authenticated, setAuthenticated] = React.useState(has_session_jwt);
  return (
    <GenericErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <StytchContext.Provider value={{authenticated, setAuthenticated}}>
          <DAppProvider config={config}>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<MainLayout />}>
                    <Route index element={<HomePage />} />
                    <Route path="/authenticate" element={<Authenticate />} />
                    <Route path="/login" element={<LoginFlat />} />
                    {/* <Route path="drops" element={<RequireAuth><DropsPage /></RequireAuth>} > */}
                    {/* <Route path="/me" element={<ProfilePage />} >
                      <Route index element={<RequireAuth><Profile /></RequireAuth>} />
                      <Route path="edit" element={<EditProfile />} />
                    </Route> */}
                    <Route path="/users" element={<ProfilePage />} >
                      <Route path=":userUUID" element={<Profile />} />
                      <Route path=":userUUID/edit" element={<EditProfile />} />
                    </Route>
                    <Route path="drops" element={<DropsPage />} >
                      <Route index element={<AllDrops />} />
                      <Route path=":dropUUID" element={<DropDetail />} />
                    </Route>
                    <Route path="creatives" element={<CreativePage />} >
                      <Route index element={<AllCreatives />} />
                      <Route path=":creativeUUID" element={<CreativeDetail />} />
                    </Route>
                    <Route path="events" element={<EventPage />} >
                      <Route index element={<AllEvents />} />
                      <Route path=":eventUUID" element={<EventDetail />} />
                    </Route>
                    <Route path="comments" element={<CommentPage />} >
                      {/* <Route index element={<AllEvents />} /> */}
                      <Route path=":commentUUID" element={<CommentDetailHOC />} />
                    </Route>
                    <Route path="forums" element={<ForumPage />} >
                      {/* <Route index element={<AllEvents />} /> */}
                      <Route path=":forumUUID" element={<ForumDetailFullHOC />} />
                    </Route>
                </Route>
              </Routes>
            </BrowserRouter>
          </DAppProvider>
        </StytchContext.Provider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </GenericErrorBoundary>
  )
}

export default App;
