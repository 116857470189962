import axios from "axios";
import {
  useQuery,
  useMutation,
  useQueryClient
} from "react-query";
 
// Get QueryClient from the context
const REACT_APP_EQ_API_DOMAIN = process.env.REACT_APP_EQ_API_DOMAIN
const REACT_APP_EQ_API_ROOT = process.env.REACT_APP_EQ_API_ROOT
const axiosInstance = axios.create({
  withCredentials: true
})

// const getForumByID = async (id) => {
//   const { data } = await axios.get(
//     `${REACT_APP_EQ_API_DOMAIN}${REACT_APP_EQ_API_ROOT}forums/${id}`
//   );
//   return data;
// };

const getCommentVotes = async (commentUUIDs) => {
  const { data } = await axiosInstance.post(
    `${REACT_APP_EQ_API_DOMAIN}${REACT_APP_EQ_API_ROOT}commentvotes/get_votes/`,
    {
      comment_uuids: commentUUIDs
    },
  );
  return data;
};

const getComments = async (forumUUID) => {
  const { data } = await axiosInstance.get(
    `${REACT_APP_EQ_API_DOMAIN}${REACT_APP_EQ_API_ROOT}comments/?forum__uuid=${forumUUID}`
  );
  return data;
};

const getComment = async (commentUUID) => {
  const { data } = await axiosInstance.get(
    `${REACT_APP_EQ_API_DOMAIN}${REACT_APP_EQ_API_ROOT}comments/${commentUUID}/`
  );
  return data;
};


const getRootComments = async (forumUUID, order, userUUID) => {
  const params = {};
  params.forum__uuid = forumUUID
  params.is_root = true
  params.user__uuid = userUUID
  params.o = order
  const { data } = await axiosInstance.get(
    `${REACT_APP_EQ_API_DOMAIN}${REACT_APP_EQ_API_ROOT}comments/`,
    {
      params
    }
  );
  return data;
};

const getReplyComments = async (rootCommentUUID, order) => {
  const { data } = await axiosInstance.get(
    `${REACT_APP_EQ_API_DOMAIN}${REACT_APP_EQ_API_ROOT}comments/`,
    {
      params: {
        root_comment__uuid: rootCommentUUID,
        o: order,
      }
    },
  );
  return data;
};

const createComment = async (payload) => {
  const cleanPayload = { ...payload }
  delete cleanPayload.rootCommentUUID;
  delete cleanPayload.forumUUID;
  const { data } = await axiosInstance.post(
    `${REACT_APP_EQ_API_DOMAIN}${REACT_APP_EQ_API_ROOT}comments/`,
    cleanPayload,
  );
  return data;
};

const deleteComment = async (payload) => {
  const { data } = await axiosInstance.delete(
    `${payload.url}`,
  );
  return data;
};

const editComment = async (payload) => {
  const cleanPayload = { 
    text: payload.text,
  }
  const { data } = await axiosInstance.patch(
    `${payload.url}`,
    cleanPayload,
  );
  return data;
};

const voteOnComment = async (url, payload) => {
  const cleanPayload = { ...payload }
  delete cleanPayload.commentUUID;
  const { data } = await axiosInstance.post(
    `${url}vote/`,
    cleanPayload,
  );
  return data;
};

export function useDeleteComment() {
  const queryClient = useQueryClient()
  return useMutation(
    (payload) => deleteComment(payload),
    {
      // Always refetch after error or success:
      onSettled: (variables) => {
        queryClient.invalidateQueries('comments',
          {
            commentUUID: variables.uuid,
          }
        )
      },
    },
  );
}

export function useEditComment() {
  const queryClient = useQueryClient()
  return useMutation(
    (payload) => editComment(payload),
    {
      // Always refetch after error or success:
      onSettled: (variables) => {
        queryClient.invalidateQueries('comments',
          {
            commentUUID: variables.uuid,
          }
        )
      },
    },
  );
}

export function useCreateComment() {
  const queryClient = useQueryClient()
  return useMutation(
    (payload) => createComment(payload),
    {
      // Always refetch after error or success:
      onSettled: (variables) => {
        queryClient.invalidateQueries('comments',
          {
            rootCommentUUID: variables.root_comment,
            forumUUID: variables.forumUUID
          }
        )
      },
    },
  );
}

export function useVoteOnComment(url) {
  const queryClient = useQueryClient()
  return useMutation(
    (payload) => voteOnComment(url, payload),
    {
      // Always refetch after error or success:
      onSettled: (variables) => {
        queryClient.invalidateQueries('comments',
          {
            commentUUID: variables.commentUUID,
          }
        )
        queryClient.invalidateQueries('commentvotes')
      },
    },
  );
}

export function useCommentVotes(commentUUIDs, authenticated) {
  return useQuery(["commentvotes", { commentUUIDs }], () => getCommentVotes(commentUUIDs), {
    enabled: !!commentUUIDs && authenticated,
  });
}

export function useComments(forumUUID) {
  return useQuery(["comments", { forumUUID }], () => getComments(forumUUID));
}

export function useComment(commentUUID) {
  return useQuery(["comments", { commentUUID }], () => getComment(commentUUID));
}


export function useRootComments(forumUUID, order, userUUID) {
  return useQuery(["comments", { forumUUID, order, userUUID }], () => getRootComments(forumUUID, order, userUUID));
}

export function useReplyComments(rootCommentUUID, order) {
  return useQuery(["comments", { rootCommentUUID, order }], () => getReplyComments(rootCommentUUID, order));
}