import axios from "axios";
import {
  useQuery,
} from "react-query";

const REACT_APP_EQ_API_DOMAIN = process.env.REACT_APP_EQ_API_DOMAIN
const REACT_APP_EQ_API_ROOT = process.env.REACT_APP_EQ_API_ROOT

const axiosInstance = axios.create({
  withCredentials: true
})


const getCollectives = async (creativeUUID) => {
  const { data } = await axiosInstance.get(
    `${REACT_APP_EQ_API_DOMAIN}${REACT_APP_EQ_API_ROOT}collectives/`,
    {
      params: {
        collective_creative__uuid: creativeUUID
      },
    },
  );
  return data;
};

const getCreative = async (creativeUUID) => {
  const { data } = await axiosInstance.get(
    `${REACT_APP_EQ_API_DOMAIN}${REACT_APP_EQ_API_ROOT}creatives/${creativeUUID}`
  );
  return data;
};

const getCreativeByURL = async (url) => {
  const { data } = await axiosInstance.get(
    `${url}`
  );
  return data;
};

const getContractAddresses = async () => {
  const { data } = await axiosInstance.get(
    `${REACT_APP_EQ_API_DOMAIN}${REACT_APP_EQ_API_ROOT}creatives/get_contract_addresses/`
  );
  return data;
};

const getMembers = async (creativeUUID) => {
  const { data } = await axiosInstance.get(
    `${REACT_APP_EQ_API_DOMAIN}${REACT_APP_EQ_API_ROOT}creatives/${creativeUUID}/members/`
  );
  return data;
};

const getMemberOf = async (creativeUUID) => {
  const { data } = await axiosInstance.get(
    `${REACT_APP_EQ_API_DOMAIN}${REACT_APP_EQ_API_ROOT}creatives/${creativeUUID}/member_of/`
  );
  return data;
};

export function useContractAddresses() {
  return useQuery(["contractAddresses"], () => getContractAddresses());
}

export function useCreativeURL(creativeURL) {
  return useQuery(["creatives", {creativeURL}], () => getCreativeByURL(creativeURL), {
    enabled: !!creativeURL,
  });
}

export function useCreative(creativeUUID) {
  return useQuery(["creatives", {creativeUUID}], () => getCreative(creativeUUID), {
    enabled: !!creativeUUID,
  });
}

export function useMembers(creativeUUID) {
  return useQuery(["creatives", {creativeUUID}, "members"], () => getMembers(creativeUUID), {
    enabled: !!creativeUUID,
  });
}

export function useMemberOf(creativeUUID) {
  return useQuery(["creatives", {creativeUUID}, "memberOf"], () => getMemberOf(creativeUUID), {
    enabled: !!creativeUUID,
  });
}

export function useCollectives(creativeUUID) {
  return useQuery(["collectives", {creativeUUID}], () => getCollectives(creativeUUID), {
    enabled: !!creativeUUID,
  });
}