import { useReplyComments, useComment, useCommentVotes } from "../../../../../hooks/comments";
import EmptyCassette from "../../../../../components/EmptyCassette";
import ReplyCommentList from "./ReplyCommentList";
import RootComment from "./RootComment";
import WriteCommentSimple from "./WriteCommentSimple"
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../../hooks/useAuth";
import LoginToComment from "../../../../../components/LoginToComment";
import MintToComment from "../../../../../components/MintToComment";
import { useHasForumAccess } from "../../../../../hooks/forums";
import { useState } from "react";
import CommentFilterTab from './CommentFilterTab';
import { generateVoteHash } from "../utils/dataHelpers";
 
function CommentDetailHOC() {
  const { authenticated } = useAuth();
  let params = useParams();
  const [order, setOrder] = useState("-created")
  
  const commentUUID = params.commentUUID
  const rootCommentAPI = useComment(commentUUID);
  const replyCommentAPI = useReplyComments(commentUUID, order);
  const hasAccessAPI = useHasForumAccess(rootCommentAPI.data && rootCommentAPI.data.forum)
  
  const commentVotesAPI = useCommentVotes(
    replyCommentAPI.data &&
    rootCommentAPI.data &&
    replyCommentAPI.data.results.map(comment => comment.uuid).concat(rootCommentAPI.data.uuid),
    authenticated,
  );
  const voteHash = generateVoteHash(commentVotesAPI.data)

  return (
    (
      rootCommentAPI.isLoading ||
      replyCommentAPI.isLoading ||
      !replyCommentAPI.data ||
      !rootCommentAPI.data
    ) ?
    <EmptyCassette />
    : <div className="flex h-full justify-center">
        <div className="flex-col w-full md:w-11/12 lg:w-8/12 space-y-8 px-4 sm:px-16 pb-4">
          <RootComment comment={rootCommentAPI.data} vote={voteHash[rootCommentAPI.data.url]}/>
          {
            authenticated ?
              hasAccessAPI.isSuccess ?
                <WriteCommentSimple
                  rootComment={rootCommentAPI.data}
                  replyingToComment={rootCommentAPI.data} />
              : <MintToComment to={"/drops"} text={ 'You must hold one of the artists NFTs to comment.' }/>
            : <LoginToComment text={ 'You must be logged in to comment.' } />
          }

          {
            replyCommentAPI.data.results.length > 1 ?
              <div>
                <CommentFilterTab order={order} action={(tabName) => setOrder(tabName)}/>
                <ReplyCommentList
                  comments={replyCommentAPI.data.results}
                  rootComment={rootCommentAPI.data}
                  includeRootComment={false}
                  order={order}
                  voteHash={voteHash} />
              </div>
            : <EmptyCassette />
          }
        </div>
      </div>
  )
}
  
  export default CommentDetailHOC;
  