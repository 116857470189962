import ReplyComment from "./ReplyComment";
import { generateCommentTree } from "../utils/dataHelpers"
import { Fragment } from "react";

function ReplyCommentList({comments, includeRootComment, rootComment, order, voteHash}) {
  const commentTree = generateCommentTree(comments, rootComment, includeRootComment, order);

  function createTreeComponent(comment) {
    return (
      <ReplyComment key={comment.uuid} comment={comment} rootComment={rootComment} vote={voteHash[comment.url]}>
        {
          comment.replies.map(
            (comment) =>
              <Fragment key={comment.uuid}>{createTreeComponent(comment)}</Fragment>
          )
        }
      </ReplyComment>
    )
  }

  return (
    <div className="flex flex-col bg-white h-fit border-2 border-slate-500 w-full rounded-md p-4">
      {commentTree.map(reply => createTreeComponent(reply))}
    </div>
  )
}
  
  export default ReplyCommentList;
  