import { useForumURL } from "../../../hooks/forums";
import EmptyCassette from "../../../components/EmptyCassette";
import ForumDetail from "./ForumDetail";


function ForumDetailHOC(props) {
  const { isLoading, data } = useForumURL(props.forum);

  return (
    (isLoading || !data) ?
    <EmptyCassette />
    : <div className="lg:w-8/12 mx-auto">
        <ForumDetail forum={data} />
      </div>
      
  )
}
  
  export default ForumDetailHOC;
  