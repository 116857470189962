import React from 'react';
import GenericCard from './GenericCard'
import EmptyCassette from './EmptyCassette'

function GenericCardList(props) {
  const { data, keyMap, loading, onClick } = props
  
  function GenericCards() {
    return (
      <div className="flex gap-2 flex-wrap mx-auto">
        {Object.keys(data).map((k) =>
          <GenericCard
            loading={loading}
            data={data[k]}
            keyMap={keyMap}
            onClick={() => onClick(data[k].uuid)}
            key={data[k].uuid} />
        )}
      </div>
    )
  }

  return (
    (!loading && !Object.keys(data).length) ?
      <EmptyCassette />
    : <GenericCards />
  )
}

export default React.memo(GenericCardList);
