import CreativeAvatarGroup from './CreativeAvatarGroup'
import TruncatedText from './TruncatedText'

function GenericCard(props) {
  const data = props.data
  const keyMap = props.keyMap
  function EmptyCard() {
    return (
      <div className="flex-none card w-96 h-128 shadow-xl animate-pulse rounded-lg">
        <figure className="bg-gray-300 h-96 w-full "> </figure>
        <div className="card-body">
          <div className="w-36 bg-gray-300 h-6 rounded-md "></div>
          <div className="w-24 bg-gray-300 h-6 rounded-md "></div>
        </div>
      </div>
    )
  }
  function LoadedCard() {
    return (
      <div className="indicator">
        {props.indicator}
      <div
        onClick={props.onClick}
        className={
          "card w-full sm:w-96 md:w-96 max-w-s shadow-xl rounded-lg bg-slate-50 " + 
          (!!props.onClick && "cursor-pointer hover:shadow-3xl hover:-translate-y-2 transition-all duration-200")
        }
      >
        
        <figure className="h-full sm:h-96 md:h-96 overflow-hidden">
            <img src={data[keyMap.image]} alt={data[keyMap.title]} />
        </figure>
        <div className="card-body relative text-white bg-stone-900">
          <div className="-top-7 absolute">
          {
            data[keyMap.avatars]?.length > 0 && 
            <CreativeAvatarGroup creativeURLs={data[keyMap.avatars]}/>
          }
          </div>
          <h2 className="card-title ">
            {data[keyMap.title]}
          </h2>
          <p>
            <TruncatedText
              text={data[keyMap.body]}
              textLength={120} />
          </p>
          <div className="card-actions justify-end">
            {props.children}
          </div>
        </div>
      </div>
      </div>
    )
  }
  return (
    !props.loading && props.data ?
      <LoadedCard />
    : <EmptyCard />
  )
}

export default GenericCard;