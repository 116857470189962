// use-api.js
import { useEffect, useState } from 'react';
import { useCallback } from 'react';
const REACT_APP_EQ_API_DOMAIN = process.env.REACT_APP_EQ_API_DOMAIN
const REACT_APP_EQ_API_ROOT = process.env.REACT_APP_EQ_API_ROOT

function buildURL(resource, params) {
  let url;
  try {
    url = URL(resource)
  } catch {
    url = new URL(`${REACT_APP_EQ_API_DOMAIN}${REACT_APP_EQ_API_ROOT}${resource}`)
  }
  
  if (params) {
    for (let k in params) {
      url.searchParams.append(k, params[k]);
    }
  }
  return url.toString()
}

const useApi = (resource, options = {}) => {
  
  const [state, setState] = useState({
    error: null,
    loading: true,
    data: null,
  });
  const [refreshIndex, setRefreshIndex] = useState(0);

  let fullURL;
  const fOptions = JSON.stringify(options);
  if (resource !== undefined) {
    fullURL = buildURL(resource, options.params)
  }

  async function makeAPICall(url, fOptions) {
    const fetchOptions = JSON.parse(fOptions)
    if (url) {
      try {
        const res = await fetch(url, {
          ...fetchOptions,
          headers: {
            ...fetchOptions.headers,
            // Add the Authorization header to the existing headers
            'Content-Type': 'application/json',
          },
          credentials: 'include'
        });
        const data = await res.json()
        return {
          success: true,
          data,
        }
      } catch (error) {
        console.log("ERROR", error)
        return {
          success: false,
          error,
        }
      }
    }
    return {
      success:false,
      error: 'not ready'
    }
  }

  const memoizedCallback = useCallback(
    async () => {
      return await makeAPICall(fullURL, fOptions);
    },
    [fullURL, fOptions],
  );


  useEffect(() => {
    (async () => {
      const result = await memoizedCallback()

      if (result.success) {
        setState((s) => {
          return {
            ...s,
            data: result.data,
            error: null,
            loading: false,
          }
        });
      } else {
        setState((s) => {
          return {
            ...s,
            error: result.error,
            loading: false,
          }
          
        });
      }
    })();
  }, [refreshIndex, memoizedCallback]);

  return {
    ...state,
    refresh: () => setRefreshIndex(refreshIndex + 1),
  };
};

export default useApi;