import RootComment from "./RootComment";
import { generateVoteHash } from "../utils/dataHelpers";

function RootCommentList({comments, votes}) {
  const voteHash = generateVoteHash(votes)
  
  return (
    <div className="flex flex-col h-full w-full gap-y-4">
      {
        comments.map(
          (comment) =>
            <RootComment key={comment.uuid} comment={comment} vote={voteHash[comment.url]}/>
        )
      }
    </div>
  )
}
  
  export default RootCommentList;
  