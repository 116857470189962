import { useNavigate } from "react-router-dom"
import GenericCard from "../../../components/GenericCard"
import { useMetadata } from "../../../hooks/metadata"
import { intToUUID } from "../../../utils/dataHelpers";

export default function OwnedNFT({ nft }) {
  const navigate = useNavigate();
  const { isLoading, data } = useMetadata(nft.tokenURI)
  const uuid = intToUUID(nft.dropID)

  const keyMap = {
    title: 'name',
    body: 'description',
    image: 'image'
  }

  return (
    <GenericCard
      loading={isLoading}
      data={data}
      keyMap={keyMap}
      onClick={() => navigate(`/drops/${uuid}`)} />
      // onClick={undefined} />
  )
}