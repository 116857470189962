const YOUTUBE = 'YOUTUBE';
const SPOTIFY = 'SPOTIFY';

const sites = {
  youtube: {
    name: YOUTUBE,
    type: 'long',
  },
  youtube_short: {
    name: YOUTUBE,
    type: 'short',
  },
  spotify: {
    name: SPOTIFY,
    type: 'default'
  }
}

function getYoutubeID(url, site_type) {
  let domain = (new URL(url));
  let vID;
  if (site_type === 'long') {
    const search = domain.search;
    const urlParams = new URLSearchParams(search);
    vID = urlParams.get('v')
  } else if (site_type === 'short') {
    vID = domain.pathname;
  }
  return vID;
}

function getSpotifyID(url, site_type) {
  let domain = (new URL(url));
  let vID = domain.pathname;
  return vID;
}
  
function getYoutubeEmbed(url, title, style, site) {
  const youtubeID = getYoutubeID(url, site.type)
  const youtubeURL = `//www.youtube.com/embed/${youtubeID}`
  return (
    <iframe style={style} title={title} width="100%" height="100%" src={youtubeURL} frameBorder="0" allowFullScreen></iframe>
  )
}

function getSpotifyEmbed(url, title, style, site) {
  const spotifyID = getSpotifyID(url);
  const spotifyURL = `https://open.spotify.com/embed${spotifyID}`
  const newStyle = {
    'borderRadius': '12px',
    ...style
  }
  return (
    <iframe style={newStyle} title={title} src={spotifyURL} width="100%" height="380" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
  )
}

function getSite(url) {
  let domain = (new URL(url));
  domain = domain.hostname.replace('www.','');
  if (domain === 'youtube.com') {
    return sites.youtube;
  }
  if (domain === 'youtu.be') {
    return sites.youtube_short;
  }
  if (domain === 'open.spotify.com') {
    return sites.spotify;
  }
  return {}
}

export function getEmbed(url, title, style) {
  if (!url || url.trim() === '') return undefined
  const site = getSite(url)
  if (site.name === YOUTUBE) {
    return (
      getYoutubeEmbed(url, title, style, site)
    )
  } else if (site.name === SPOTIFY) {
    return (
      getSpotifyEmbed(url, title, style, site)
    )
  }
  return undefined;
}