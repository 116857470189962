import { useState, useEffect } from 'react';
import CommentIcon from "../../../../../components/icons/CommentIcon";
import UpVoteIcon from "../../../../../components/icons/UpVoteIcon";
import EditIcon from "../../../../../components/icons/EditIcon";
import DeleteIcon from "../../../../../components/icons/DeleteIcon";
import { DateTime } from "luxon";
import ReactMarkdown from 'react-markdown'
import remarkGemoji from 'remark-gemoji'
import WriteCommentSimple from './WriteCommentSimple';
import EditCommentSimple from './EditCommentSimple';
import { useAuth } from '../../../../../hooks/useAuth';
import { useDeleteComment, useVoteOnComment } from '../../../../../hooks/comments';
import { useHasForumAccess } from '../../../../../hooks/forums';
import CommentAvatar from '../../../../../components/CommentAvatar';
import { Link } from 'react-router-dom';
import { useMe } from '../../../../../hooks/users';
import GenericModal from '../../../../../components/GenericModal';

function ReplyComment({comment, children, rootComment, vote}) {
  const {authenticated} = useAuth();
  const meAPI = useMe(authenticated);
  
  const [voteState, setVoteState] = useState(vote)

  const hasAccessAPI = useHasForumAccess(comment.forum);
  const [actionsEnabled, setActionsEnabled] = useState({
    write: false,
    edit: false,
  })
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const voteMutation = useVoteOnComment(comment.url);
  const deleteMutation = useDeleteComment();
  
  const writeDisabled = !actionsEnabled.write || actionsEnabled.edit || !authenticated || !hasAccessAPI.data?.has_access;
  const buttonsDisabled = !authenticated || !hasAccessAPI.data?.has_access;

  function handleActionChange(newAction) {
    if (newAction.write === true) {
      setActionsEnabled({
        edit: false,
        write: true,
      })
    } else if (newAction.write === false) {
      setActionsEnabled({
        ...actionsEnabled,
        write: false,
      })
    } else if (newAction.edit === true) {
      setActionsEnabled({
        edit: true,
        write: false,
      })
    } else if (newAction.edit === false) {
      setActionsEnabled({
        ...actionsEnabled,
        edit: false,
      })
    }
  }

  function upVoteComment() {
    const commentUUID = comment.uuid;
    const newVote = voteState > 0 ? -1 : 1 
    voteMutation.mutate({
      commentUUID: commentUUID,
      vote: newVote,
    })
    comment.score += newVote;
    setVoteState(newVote);
  }

  function deleteComment() {
    deleteMutation.mutate({
      url: comment.url,
      uuid: comment.uuid,
    })
    setConfirmDeleteOpen(false)
  }

  function confirmDeletion() {
    setConfirmDeleteOpen(true)
  }

  useEffect(() => {
    setVoteState(vote);
  }, [vote])

  return (
    <div className="flex flex-row  border-slate-500 w-full-md pt-8 pb-4 last:pb-0">
      <div className="flex flex-col w-8">
      <Link to={`/users/${comment.user.uuid}`}><CommentAvatar user={comment.user}/></Link>
        <div className="mx-auto pt-2 h-full divider divider-horizontal"></div> 
      </div>
      <div className="py-1 px-2 w-full">
        <Link
          to={`/users/${comment.user.uuid}`}
          className="hover:underline hover:text-violet-500">
          <span className="text-sm font-bold">{comment.user.handle}</span>
        </Link>
        <span className="text-sm font-light">
          <span className="text-xs font-light"> • </span>
          {DateTime.fromISO(comment.created).toRelative()}
        </span>
        <div className="w-full max-w-none text-slate-900">
          {
            !actionsEnabled.edit ?
              <ReactMarkdown className="mt-2 mb-0 pl-4" children={comment.text} remarkPlugins={[remarkGemoji]} />
            : <EditCommentSimple
                comment={comment}
                onSuccess={() => handleActionChange({edit: false})}/>
          }
          <div className="flex flex-row w-full mt-2">
            <div>
              <UpVoteIcon disabled={buttonsDisabled} text={comment.score} action={() => upVoteComment()} filled={voteState > 0} size={4}/>
            </div>
            <div>
              <CommentIcon disabled={buttonsDisabled} text="Reply" action={() => handleActionChange({write:!actionsEnabled.write})} size={4}/>
            </div>
            {
              meAPI?.data?.uuid === comment.user.uuid &&
              <div>
                <EditIcon disabled={buttonsDisabled} text="Edit" action={() => handleActionChange({edit:!actionsEnabled.edit})} size={4}/>
              </div>
            }
            {
              (meAPI?.data?.uuid === comment.user.uuid && !comment.deleted) &&
              <div>
                <DeleteIcon disabled={buttonsDisabled} text="delete" action={() => confirmDeletion()} size={4}/>
              </div>
            }
          </div>
        </div>
        <div className="mt-4">
          {
            !writeDisabled &&
            <WriteCommentSimple
              rootComment={rootComment}
              replyingToComment={comment}
              forumURL={rootComment.forum}
              onSuccess={() => handleActionChange({write: false})}/> }
        </div>
        
        <div className="w-full pl-3">
          { children }
        </div>
      </div>
      <GenericModal
        open={confirmDeleteOpen}
        confirm={() => deleteComment()}
        deny={() => setConfirmDeleteOpen(false)}
        title="Are you sure you want to delete?"
        body="If you delete this comment it will be removed forever."
        confirmText="delete"
        denyText="keep"
      />
    </div>
  )
}
  
export default ReplyComment;
  