import Cookies from 'js-cookie'
import { useNavigate } from "react-router-dom";
import { useEthers } from "@usedapp/core";
import { useAuth } from '../hooks/useAuth';
import { useLogout } from '../hooks/users';

const LogoutButton = () => {
  const navigate = useNavigate();
  const { deactivate } = useEthers()
  const { setAuthenticated } = useAuth();
  const logoutMutation = useLogout()

  async function removeSessions() {
    Cookies.remove('eq_stytch_jwt')
    Cookies.remove('eq_logged_in')
    try {
      deactivate()
      await logoutMutation.mutateAsync()
      setAuthenticated(false)
      navigate('/')
    } catch (error) {
      setAuthenticated(false)
      navigate("/");
    }
  }

  return (
    <button onClick={() => removeSessions()}>
      Log Out
    </button>
  );
};

export default LogoutButton;