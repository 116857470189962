
  import { Outlet } from "react-router-dom";

  function EventPage() {
    return (
      <div className="">
        <Outlet />
      </div>
    )
  }
  
  export default EventPage;
  