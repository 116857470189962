import { useState } from "react";
export default function BounceClickIcon(props) {
  const [effect, setEffect] = useState(false);
  const fillColor = props.filled ? 'fill-violet-300' : 'fill-transparent';
  let classes = 'h-6 w-6'
  if (props.size) {
    classes = `h-${props.size} w-${props.size}`
  }

  function clickHandler() {
    setEffect('animate-bounce-up')
    props.action();
    
  }

  return (
    <button
      disabled={props.disabled}
      onClick={() => clickHandler()}
      className={`disabled:bg-transparent btn rounded-sm bg-transparent text-slate-700 border-0 hover:bg-slate-200`}>
      <svg 
        onAnimationEnd={() => setEffect('')} 
        xmlns="http://www.w3.org/2000/svg" 
        className={`${classes} ${effect} ${fillColor}`} 
        viewBox="0 0 24 24" 
        stroke="currentColor" 
        strokeWidth={2}>
        { props.children }
      </svg>
      <span className="ml-2">{props.text}</span>
    </button>
  )
}