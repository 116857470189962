import { stringify } from 'uuid'
import { BigNumber, utils } from 'ethers'

export function normalizeData(data, key) {
  let normalizedDrops = {}
  if (data) {
    normalizedDrops = data.reduce(
      (prev, curr) => {
        prev[curr[key]] = curr;
        return prev;
      },
      {}
    )
  }
  return normalizedDrops;
}

export function simpleSearchData(data, query) {
  const cleanQuery = query?.toLowerCase() || ''
  let filteredData = data && data.results;
  if (filteredData) {
    filteredData = filteredData.filter(
      (fd) => {
        if (cleanQuery !== '') {
          return fd.name.toLowerCase().includes(cleanQuery)
        }
        return true
      }
    )
  }
  return filteredData
}

export function bpsToPct(num) {
  if (!num) return 0
  return (num / 100)
}

export function intToUUID(intStr) {
  const bn = BigNumber.from(intStr)
  const hexString = bn.toHexString()
  const bArray = utils.arrayify(hexString)
  return stringify(bArray);
}