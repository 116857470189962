import { useState } from "react"
import { useNavigate } from "react-router-dom"
import RootCommentListHOC from "../../forums/routes/comments/components/RootCommentListHOC"
import CommentFilterTab from "../../forums/routes/comments/components/CommentFilterTab"
import AddressBadge from "../../../components/AddressBadge"
import TitleDivider from "../../../components/TitleDivider"
import OwnedNFT from "./OwnedNFT"
import EditProfileButton from "./EditProfileButton"
import ProfilePicture from "./ProfilePicture"
import NFTOwnerStats from "./NFTOwnerStats"
import EmptyCassette from "../../../components/EmptyCassette"

export default function ProfileDetail({ me, user, nftsOwned }) {
  const [order, setOrder] = useState("-created")
  const navigate = useNavigate();
  const isSelf = me?.uuid === user.uuid

  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex flex-row prose w-full max-w-none">
        <ProfilePicture handle={ user.handle || 'u' } image={user.image} isSelf={isSelf}/>
        <div className="my-auto ml-4">
          <h1 className="mb-2">
            {user.handle}
          </h1>
          {user.wallets.length > 0 && <AddressBadge address={user.wallets[0].address}/>}
        </div>
        <div className="ml-auto my-auto">
          {isSelf && <EditProfileButton action={() => navigate('edit')}/>}
        </div>
      </div>
      {/* <p>{user.bio}</p> */}
      <NFTOwnerStats total={nftsOwned?.totalCount || 0} distinct={nftsOwned?.distinctCreativeCount || 0} />
      <TitleDivider title={ 'NFTs Owned' }/>
      {
        nftsOwned && nftsOwned.totalCount > 0 ?
          <div className="flex flex-row gap-2 flex-wrap mx-auto">
            {nftsOwned.transformedTokenInfo.map(
              (nft) => (
                
                  <OwnedNFT key={nft.tokenID} nft={nft} />
                
              )
            )}
          </div>
        : <EmptyCassette />
      }
      <div className="flex flex-col h-full w-full justify-center">
        <TitleDivider title={ 'Posts' }/>
        <CommentFilterTab order={order} action={(tabName) => setOrder(tabName)}/>
        <RootCommentListHOC userUUID={user.uuid} order={order}/>
      </div>
    </div>
  )
}