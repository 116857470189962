export default function GenericModal({
  open,
  confirm,
  deny,
  title,
  body,
  confirmText,
  denyText,
}) {
  return (
    <div>
      <input type="checkbox" id="my-modal-6" className="modal-toggle" checked={open} onChange={()=> void(0)}/>
      <div className="modal modal-bottom sm:modal-middle" onClick={deny}>
        <div className="modal-box relative" onClick={(e) => e.stopPropagation()}>
          <label onClick={deny} className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
          <h3 className="font-bold text-lg">{title}</h3>
          <p className="py-4">{body}</p>
          <div className="modal-action">   
            <button 
              onClick={() => deny()}
              className="btn btn-outline btn-primary">{denyText}
            </button>
            <button 
              onClick={() => confirm()}
              className="btn btn-primary">{confirmText}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}