import { useState } from 'react'
import creativeABI from '../abis/creative_abi.json'
import { utils } from 'ethers'
import { Contract } from '@ethersproject/contracts'
import {
  useContractFunction,
} from '@usedapp/core'

function MintDropButton(props) {
  const [isLoading, setIsLoading] = useState(false)
  const creativeInterface = new utils.Interface(creativeABI)
  const creativeContractAddress = props.contractAddress
  const contract = new Contract(creativeContractAddress, creativeInterface)
  
  const { send } = useContractFunction(contract, 'mintNFTDirect', { transactionName: 'mintNFTDirect' })

  const mintDrop = async () => {
    setIsLoading(true)
    await send(props.dropID, utils.formatBytes32String(''), { value: utils.parseEther(props.price) })
    setIsLoading(false)
  }

  return (
    isLoading ?
    <button className="btn btn-primary loading" disabled={true}>Mint NFT</button>
    : <button className="btn btn-primary" onClick={mintDrop}>{`Mint NFT`}</button>
  )
}

export default MintDropButton