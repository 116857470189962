import { useNFTs } from "../../../hooks/alchemy";
import { useUser, useMe } from "../../../hooks/users";
import { useContractAddresses } from "../../../hooks/creatives";
import { transformTokenInformation } from "../utils/dataHelpers";
import ProfileDetail from "./ProfileDetail";
import EmptyCassette from "../../../components/EmptyCassette";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";

function Profile() {
  let params = useParams();
  const { authenticated } = useAuth();
  const userUUID = params.userUUID;
  const userAPI = useUser(userUUID);
  const meAPI = useMe(authenticated);
  const contractAddressesAPI = useContractAddresses()
  const nftAPI = useNFTs(userAPI.data?.wallets[0]?.address, contractAddressesAPI.data?.results)
  const nftsOwned = transformTokenInformation(nftAPI.data);

  return (
    <div className="flex flex-col h-full w-full justify-center lg:w-8/12 mx-auto">
      {
        userAPI.isLoading || !userAPI.data ?
          <EmptyCassette />
        : <ProfileDetail me={meAPI.data} user={userAPI.data} nftsOwned={nftsOwned} />
      }
    </div>
  )
}
  
  export default Profile;
  