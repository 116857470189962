
   
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { useAuth } from "../../hooks/useAuth";

const REACT_APP_EQ_API_DOMAIN = process.env.REACT_APP_EQ_API_DOMAIN

const Authenticate = () => {
  const authUrl = `${REACT_APP_EQ_API_DOMAIN}stytch/`
  const { authenticated, setAuthenticated } = useAuth();

  const { search } = useLocation();
  const token = queryString.parse(search).token;
  if (typeof token !== "string") {
    throw new Error("No valid token provided.");
  }
  const navigate = useNavigate();
  React.useEffect(() => {
    if (authenticated) {
      return
    }
    const authenticate = async () => {
      try {        
        const response = await fetch(authUrl, {
          method: "POST",
          body: JSON.stringify({
            token: token,
          }),
          headers: {
            "Content-Type": "application/json",
          },
          credentials: 'include'
        });
        if (response.ok) {
          // TODO: Add database call to get user and set information here.
          setAuthenticated(true);
          navigate("/");
        } else {
          navigate("/login");
        }
      } catch (err) {
        console.error("Error authenticating magic link");
        navigate("/login");
      }
    };

    authenticate();
  }, [authenticated, authUrl, navigate, setAuthenticated, token]);

  return <React.Fragment />;
};

export default Authenticate;