import { useState } from "react";
import CommentFilterTab from "../routes/comments/components/CommentFilterTab";
import RootCommentListHOC from "../routes/comments/components/RootCommentListHOC";

function ForumDetail({forum}) {
  const [order, setOrder] = useState("-created")

  return (
    <div className="flex flex-col h-full w-full justify-center mx-auto">
      <div className="flex-row mb-4">
        <CommentFilterTab order={order} action={(tabName) => setOrder(tabName)}/>
        </div>
      <div className="flex h-full w-full justify-center">
        <RootCommentListHOC forumUUID={forum.uuid} order={order}/>
      </div>
    </div>
  )
}
  
  export default ForumDetail;
  