import creativeABI from '../../../abis/creative_abi.json'
import { utils } from 'ethers'
import { Contract } from '@ethersproject/contracts'
import {
  useCall,
} from '@usedapp/core';

export default function SupplyProgress({dropID, max, contractAddress, price}) {
  const creativeInterface = new utils.Interface(creativeABI)
  const creativeContractAddress = contractAddress
  const contract = new Contract(creativeContractAddress, creativeInterface)
  
  const call = {
    contract: contract,
    method: 'drops',
    args: [dropID]
  }
  
  const { value } = useCall(call) ?? { value: 0, error: undefined}
  const currentSupply = value && value.currentSupply.toString();
  return (
    <div>
      <div className="prose flex flex-row space-x-4">
        <h3 className="">
          {currentSupply}/{max} sold
        </h3>
      </div>
      <div className="flex flex-col tooltip tooltip-bottom" data-tip={`${currentSupply} of ${max}`}>
        <progress
          className="progress progress-primary w-full" 
          value={currentSupply}
          max={max}></progress>
      </div>
      <div className="prose flex flex-row space-x-4">

        <span>{price} MATIC per NFT</span>
      </div>
    </div>
  )
}