import {
  Link,
  useNavigate,
} from "react-router-dom";
import FeaturedCreative from '../creatives/components/FeaturedCreative';
import useApi from '../../hooks/useApi'
import { useAuth } from '../../hooks/useAuth'
import LoginFlat from '../login/LoginFlat'
import CreativeList from '../../routes/creatives/components/CreativeList';

function HomePage() {
  const { authenticated } = useAuth();
  const navigate = useNavigate()
  const opts = {
    method: 'GET',
  };
  const { loading, data } = useApi(
    `creatives/get_featured/`,
    opts
  );
  return (
    <div className="flex h-full justify-center">
      <div className="flex-col space-y-8 px-4 sm:px-16 pb-4">
        <h1 className="text-center text-3xl lg:text-5xl font-bold">Invest in creatives. Join their journey.</h1>
        <div className="flex flex-col lg:flex-row space-y-12 lg:space-y-0 lg:space-x-12 lg:justify-center md:w-8/12 md:mx-auto lg:w-full ">
          <div className="w-full lg:w-5/12">
            <FeaturedCreative
              onClick={() => navigate(`/creatives/${data.uuid}`)}
              loading={loading}
              image={data ? data.image : undefined}
              title={data ? data.name : undefined}
              body={data ? data.bio : undefined}>
              <button className="btn btn-primary">View</button>
            </FeaturedCreative>
          </div>
          {
            !authenticated && 
            <div className="">
              <LoginFlat />
            </div>
          }
        </div>
        <div className="mt-48 mx-auto">
          <div className="flex flex-row mt-8">
            <h1 className="text-xl font-bold w-6/12">Latest Creatives</h1>
            <h1 className="text-right w-6/12">
              <Link className="hover:text-slate-900 hover:underline text-xl text-slate-700 font-medium" to='/creatives'>View All</Link>
            </h1>
          </div>
          <div className="divider mt-4"></div>
          <CreativeList />
        </div>
      </div>
    </div>
  )
}
  
  export default HomePage;
  