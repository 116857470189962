import '../App.css';
import { useAuth } from '../hooks/useAuth';
import {
  Link
} from "react-router-dom";
import NavBarProfileItem from './NavBarProfileItem';
import LogoutButton from './LogoutButton';

function NavBar() {
  const {authenticated} = useAuth();
  
  return (

    <div className="navbar">
      <div className="navbar-start">
        <div className="dropdown">
          <label tabIndex="0" className="btn btn-ghost lg:hidden">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
          </label>
          <ul tabIndex="0" className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52">
            {
              authenticated ?
                <NavBarProfileItem text="Profile"/>
              : <li><Link to='/login'>Login</Link></li>
            }
            <li><Link to='/creatives'>Creatives</Link></li>
            <li><Link to='/drops'>Drops</Link></li>
            <li><Link to='/events'>Events</Link></li>
            {authenticated && <li><LogoutButton /></li>}
          </ul>
        </div>
        <Link to='/'>
          <button className="btn btn-ghost text-primary normal-case text-xl">EQ</button>
        </Link>
      </div>
      <div className="navbar-end">
        <ul className="menu menu-horizontal p-0 hidden lg:flex">
          <li><Link to='/creatives'>Creatives</Link></li>
          <li><Link to='/drops'>Drops</Link></li>
          {authenticated && <li><LogoutButton /></li>}
          {
            authenticated ?
              <NavBarProfileItem />
            : <li><Link to='/login'>Login</Link></li>
          }
        </ul>
      </div>
    </div>
  )
}

export default NavBar;
