
  import { Outlet } from "react-router-dom";

  function CreativePage() {
    return (
      <div className="">
        <Outlet />
      </div>
    )
  }
  
  export default CreativePage;
  