import axios from "axios";
import {
  useQuery,
} from "react-query";

const axiosInstance = axios.create({
  // crossdomain: true,
  // headers: {
  //   "Content-Type": "application/json",
  //   "Access-Control-Allow-Origin": "*"
  // },
})

const getMetadata = async (url) => {
  const { data } = await axiosInstance.get(
    `${url}?not-from-cache-please`,
  );
  return data;
};

export function useMetadata(url) {
  return useQuery(["metadata", url], () => getMetadata(url), {
    enabled: !!url,
  });
}